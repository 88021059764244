<div fxFlex="100" style="align-content: center; align-items: center; margin: 0">
  <div fxFlex style="white-space: pre-line">
    {{transactions}}
  </div>
  <div fxFlex="12" style="padding-left:5px" *ngIf="showButton">
    <button mat-icon-button  matTooltip="Refund" matTooltipPosition="above" color="primary"
            (click)="refundAppointment()">
      <mat-icon>money_off</mat-icon>
    </button>
  </div>
</div>

