import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { IAppointmentInstance } from 'src/app/_shared/interfaces/appointment-instance';
import { BackendService } from 'src/app/_shared/services/backend.service';
import * as moment from 'moment-timezone';
import { dateComparator, defaultColDef, momentDateFilterParams } from 'src/app/_shared/AgGridUtils';

@Component({
  selector: 'app-instances-modal',
  templateUrl: './instances-modal.component.html',
  styleUrls: ['./instances-modal.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class InstancesModalComponent implements OnInit {
  private grStatusMap = ['Upcoming', 'Sending', 'Sent', 'Failed', 'Action', 'Not sent', 'Delivered']
  private columnDefs: ColDef[] = [
    {
      headerName: 'ID', field: 'id', flex: 0.8, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Date', field: 'instanceDate', flex: 1.2, wrapText: true, floatingFilter: true,
      suppressMenu: true, filter: 'agDateColumnFilter', filterParams: momentDateFilterParams, comparator: dateComparator
    },
    {
      headerName: 'Client', field: 'name', flex: 0.8, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Reminder', field: 'reminder', flex: 1, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Reminder Status', field: 'gr_status', flex: 0.7, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
  ]

  rowData: any = []
  gridOptions = {
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    columnDefs: this.columnDefs,
  }
  visibleRows: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InstancesModalComponent>,
    private backendSrv: BackendService
  ) { }

  ngOnInit(): void {
    this.backendSrv.getAppointmentInstances(this.data.id).subscribe((resp: IAppointmentInstance[]) => {
      const reminders = this.data.reminders ? this.data.reminders.split('\\n') : ''
      const msg_ids = this.data.message_ids ? this.data.message_ids.split(',') : ''
      resp.forEach((inst: IAppointmentInstance) => {
        inst.gr_status = this.grStatusMap[inst.gr_status] ? this.grStatusMap[inst.gr_status] : 'unknown'
        inst.instanceDate = moment(inst.start).tz(this.data.timezone).format('dd MMM D YYYY, HH:mm:ss') + ' - ' +
          moment(inst.end).tz(this.data.timezone).format('HH:mm:ss') + ' ' + this.data.timezone
        inst.reminder = reminders[msg_ids.indexOf(inst.message_id)]
      })
      // console.log(this.data, resp)
      this.rowData = resp
    })
  }

  countDisplayedRows(params: any) { this.visibleRows = params.api.getDisplayedRowCount() }

  closeDialog() { this.dialogRef.close('') }
}