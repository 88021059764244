<div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
    <div class="actionBar" fxFlexOffset="7.5" fxFlex="initial">
        <div fxFlexFill fxLayoutAlign="start center">
            <button mat-raised-button color="accent" (click)="navigateToUser()">View User</button>
        </div>
    </div>
    <div fxFlex style="overflow: auto;" fxFlexOffset="1">
        <div fxLayout="row">
            <pre>GOLDIE {{purchases}}</pre>
            <pre>REVENUE CAT {{subscriberInfo}}</pre>
        </div>
    </div>
</div>
