<div fxLayout="row" fxFlexFill>
  <div fxFlex="8.5"></div>
  <div fxFlex="83" *ngIf="user">
    <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <div fxFlexOffset="10" style="width: 100%;">
        <div fxFlexFill fxLayoutAlign="start space-between" fxLayout="column">
          <span fxLayout="row" fxFlexOffset="3">
            <div fxFlex="33"></div>
            <img fxFlex="13" [src]="user.photoURL">
            <div fxFlex fxLayout="column" fxFlexOffset="12" fxLayoutAlign="end">
              <div fxFlex fxLayout="column" fxLayoutAlign="end">
                <div fxLayout="row">
                  <button fxLayoutAlign="center center" color="warn" style="width: 150px; border-radius: 0px;"
                    mat-raised-button (click)="signOut()">
                    <!-- <mat-icon class="mr">power_off</mat-icon> -->
                    Sign out
                  </button>
                </div>
                <div fxFlex fxLayout="row" fxFlexOffset="3">
                  <h3>Dark theme</h3>
                  <mat-slide-toggle fxFlexOffset="5" [formControl]="toggleControl" class="mr-8"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </span>
          <span fxFlexOffset="3">
            <div fxFlex="33"></div>
            <h1>Hello {{user.displayName}}</h1>
          </span>
          <span fxFlexOffset="2" *ngIf="!validEmail">
            <div fxFlex="33"></div>
            <h3>In order to have your user validated, please login using a
              Mobiversal email.</h3>
          </span>
          <span fxFlexOffset="2" *ngIf="validEmail">
            <div fxFlex="33"></div>
            <h3>You have signed in with Google, using {{user.email}}.</h3>
          </span>
          <span>
            <div fxFlex="33"></div>
            <h3>Account is {{auth.canRead(user) ? 'active.':'not active.'}}</h3>
          </span>
        </div>
      </div>
      <div fxFlex></div>
    </div>
  </div>
</div>