import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { blanksFilterOptionsText, dateFilterParams, defaultColDef } from '../_shared/AgGridUtils';
import { IAppUserClient } from '../_shared/interfaces/app-user-client';
import { BackendService } from '../_shared/services/backend.service';

@Component({
  selector: 'app-user-clients',
  templateUrl: './user-clients.component.html',
  styleUrls: ['./user-clients.component.sass']
})
export class UserClientsComponent implements OnInit {
  uid: string | null = '';
  userName: string | null = '';
  userTz: string = '';
  searchValue: string = '';
  visibleRows: any = false
  rowData: IAppUserClient[] = [];
  columnDefs: ColDef[] = [
    {
      headerName: 'ID', field: 'id', flex: 2, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Email', field: 'email', flex: 2, wrapText: true,
      suppressMenu: true, floatingFilter: true
    },
    {
      headerName: 'Name', field: 'name', flex: 1.5, wrapText: true,
      suppressMenu: true, floatingFilter: true
    },
    {
      headerName: 'Notes', field: 'notes', flex: 1.5, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Phone', field: 'phone', flex: 1.5, wrapText: true,
      suppressMenu: true, floatingFilter: true, filterParams: { filterOptions: blanksFilterOptionsText }
    },
    {
      headerName: 'SMS Status', field: 'sms_status', flex: 1, wrapText: true,
      suppressMenu: true, floatingFilter: true
    },
    {
      headerName: 'Sending NR', field: 'sending_number', flex: 1, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Updated at', field: 'updated_at', flex: 1.5, wrapText: true, suppressMenu: true,
      filter: 'agDateColumnFilter', floatingFilter: true, filterParams: dateFilterParams
    }
  ]
  gridOptions = {
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    columnDefs: this.columnDefs,
  }
  private gridApi: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private backendSrv: BackendService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.uid = params.get('uid')
      this.backendSrv.userData$.subscribe(userData => {
        if (userData) {
          this.userName = userData.userData[0].name
          this.userTz = userData.businessData[0].timezone
          this.getUserClients()
        }
        else this.backToUser()
      })
    })
  }

  getUserClients() {
    this.backendSrv.getUserClients(this.uid || '').subscribe((data: IAppUserClient[]) => {
      this.rowData = data
      data.forEach((client: any, index: number) => {
        this.rowData[index].updated_at = client.updated_at.split('T').join(' ').split('.')[0] + ' ' + this.userTz
      });
    })
  }

  resetAllFilters() {
    this.gridApi.setFilterModel(null);
    this.searchValue = ''
  }

  onGridReady(params: any) { this.gridApi = params.api }

  countDisplayedRows(params: any) { this.visibleRows = params.api.getDisplayedRowCount() }

  onBtnExport() { this.gridApi.exportDataAsCsv() }

  backToUser() { this.router.navigate(['admin', 'view', 'user', this.uid]) }

  goToAppointments() { this.router.navigate(['admin', 'view', 'user', this.uid, 'appointments']) }

  goToMassMessages() { this.router.navigate(['admin', 'view', 'user', this.uid, 'mass-messages']) }

}