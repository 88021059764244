import { Component, Input } from '@angular/core';
import { makeWorkingHoursArray } from 'src/app/_shared/AgGridUtils';
import { BackendService } from 'src/app/_shared/services/backend.service';

@Component({
  selector: 'business-data-panel',
  templateUrl: './business-data-panel.component.html',
  styleUrls: ['./business-data-panel.component.sass']
})
export class BusinessDataPanelComponent {
  @Input() businessData: any = {};
  bookingLinkMap: any = {
    Test: 'https://test.book.heygoldie.com/',
    Live: 'https://book.heygoldie.com/',
    Development: 'https://dev.book.heygoldie.com/',
    Replica: 'https://book.heygoldie.com/'
  }

  constructor(
    private backendSrv: BackendService
  ) { }

  ngOnInit(): void {
    this.businessData.working_time = makeWorkingHoursArray(this.businessData)
  }

  makeBookingLink(businessLink: string) { return `${this.bookingLinkMap[this.backendSrv.env]}${businessLink}` }

}
