import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-general-snack-bar',
  templateUrl: './general-snack-bar.component.html',
  styleUrls: ['./general-snack-bar.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralSnackBarComponent {
  title = ''
  description = ''

  constructor(
    private snackBarRef: MatSnackBarRef<GeneralSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) private data: any
  ) {
    this.title = this.data.title
    this.description = this.data.description
  }

  public dismissSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
