<mat-toolbar fxLayout="row" color="primary">
    <div fxFlex="7"></div>
    <div fxFlex="87" fxLayoutAlign="center center">
        <mat-icon *ngIf="!(onlineStatus$ | async)" fxFlex="2.5" color="accent">wifi_off</mat-icon>
        <span fxFlex *ngIf="auth.canRead(user)">Goldie - {{backendSrv.environment$ | async | uppercase}}</span>
        <span fxFlex fxLayoutAlign="start center" *ngIf="!auth.canRead(user)">Pending approval. Contact your
            system administrator for assistance.</span>
        <div *ngIf="auth.canRead(user)">
            <button mat-button *ngFor="let item of menuItems" (click)="navigateTo(item.route)"
                [fxShow]="item.showOnDesktop" [fxShow.xs]="item.showOnMobile" [fxShow.sm]="item.showOnTablet">
                <mat-icon class="mr">{{item.icon}}</mat-icon>
                {{item.label}}
            </button>
        </div>
        <button *ngIf="auth.canRead(user)" mat-button [fxShow]="showEnv" [fxShow.lt-sm]="false"
            [matMenuTriggerFor]="envMenu">
            <mat-icon class="mr">alt_route</mat-icon>
            Environment
        </button>
        <span *ngIf="user" (click)="navigateTo('user-profile')">
            <img [src]="user.photoURL" class="userImage">
        </span>
        <mat-menu #envMenu="matMenu">
            <ng-container *ngFor="let env of environments">
                <button mat-menu-item (click)="setEnvironment(env)">
                    {{env}}
                </button>
            </ng-container>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="loader-container" fxLayoutAlign="center center" *ngIf="loaderService.isLoading | async">
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
        <h3 fxFlex="5" fxFlexOffset="5" *ngIf="loaderService.isLongLoading | async">
            Please be patient. Requests on Live environment might take some time..
        </h3>
    </div>
</div>
<div fxLayout="row" fxFlexFill>
    <div fxFlex="7.5"></div>
    <div fxFlex="85">
        <div fxFlex [@routeAnimations]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
    </div>
    <div fxFlex="7.5"></div>
</div>
