<div class="modalWrapper mat-app-background">
  <div mat-dialog-title>
    <span fxFlex="28">Appointment Info</span>
    <span fxFlex>Instances</span>
    <span fxFlex>{{visibleRows}}</span>
    <button mat-icon-button color="warn" class="closeButton" (click)="closeDialog()">
      <mat-icon>wc</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content fxLayout="row" class="modalContent">
    <div fxFlex="28" class="nonGridContent">
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Appt. ID</span>
        <span fxFlex>{{data.id}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Type</span>
        <span fxFlex>{{data.type}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Status</span>
        <span fxFlex>{{data.status}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Appt. date</span>
        <span fxFlex>{{data.appointment_date}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Clients</span>
        <span fxFlex>{{data.clients}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Services</span>
        <span fxFlex>{{data.services}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Price</span>
        <span fxFlex>{{data.price_formatted}}</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Extra Time</span>
        <span fxFlex>{{data.extra_time}}m</span>
      </div>
      <div>
        <span fxFlex="28" class="appointmentInfoSubtitle">Reminders</span>
        <span fxFlex>{{data.reminders}}</span>
      </div>
      <div *ngIf="data.notes">
        <span fxFlex="28" class="appointmentInfoSubtitle">Note</span>
        <span fxFlex>{{data.notes}}</span>
      </div>
    </div>
    <div fxFlex>
      <ag-grid-angular class="instancesGrid ag-theme-material" (rowDataChanged)="countDisplayedRows($event)"
        [rowData]="rowData" [gridOptions]="gridOptions">
      </ag-grid-angular>
    </div>
  </mat-dialog-content>
</div>