import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_shared/services/auth.service';
import { BackendService } from '../_shared/services/backend.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class EditUserComponent implements OnInit {
  @ViewChild("expiryDate") expiryDatePicker!: ElementRef;

  uid: string | null = '';
  bid: string = '';
  editUserForm: FormGroup;
  user: any;
  plans: any = [];
  initialValues: any = {
    email: '',
    userPassword: '',
    userNotes: undefined,
    plan: undefined,
    price: undefined,
    currency: undefined,
    expiryDate: undefined,
    pack: undefined,
    transactionLimit: undefined,
    payoutLimit: undefined,
  }
  monthlyReminders: string = 'Monthly Reminders: '
  smsPack: any = []
  isBlocked: boolean = false;
  isDeleted: boolean = false;
  sameValues: boolean = true;
  isLiveEnv: boolean = false;
  formData: any;
  signInMethod: string = '';
  userName: string = '';
  userRoleOwner: boolean = false;
  currencyList: any = [];

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private backendSrv: BackendService,
    private fb: FormBuilder,
  ) {
    this.route.paramMap.subscribe(params => {
      this.uid = params.get('uid')
      this.backendSrv.userData$.subscribe(userInfo => {
        userInfo && userInfo.userData[0].id === this.uid ?
          this.processUserInfo(userInfo) : this.backendSrv.getUserInfo(this.uid || '').subscribe(userInfo => this.processUserInfo(userInfo))
      })
    })
    this.editUserForm = this.fb.group({
      email: [this.initialValues.email, [Validators.required, Validators.email]],
      userNotes: [this.initialValues.userNotes],
      userPassword: [this.initialValues.userPassword],
      plan: [this.initialValues.plan],
      price: [this.initialValues.price],
      currency: [this.initialValues.currency],
      expiryDate: [this.initialValues.expiryDate, [Validators.required]],
      pack: [this.initialValues.pack],
      transactionLimit: [this.initialValues.transactionLimit],
      payoutLimit: [this.initialValues.payoutLimit],
    })
  }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => this.user = user)
    this.editUserForm.valueChanges.subscribe(data => {
      const orderedData = sortObjKeys(data)
      const orderedInitialData = sortObjKeys(this.initialValues)
      JSON.stringify(orderedData) === JSON.stringify(orderedInitialData) ?
        this.sameValues = true : this.sameValues = false
    })
  }

  planChanged() {
    this.editUserForm.controls['expiryDate'].patchValue(null)
    this.editUserForm.controls['price'].patchValue(null)
    this.expiryDatePicker.nativeElement.focus()
  }

  submitChanges() {
    if (!this.sameValues) {
      const changePrice = this.editUserForm.controls['price'].value !== this.initialValues.price
      const changePlan = this.editUserForm.controls['expiryDate'].value !== this.initialValues.expiryDate
      const addSms = this.editUserForm.controls['pack'].value !== this.initialValues.pack
      if (this.editUserForm.controls['email'].value !== this.initialValues.email) this.changeEmail()
      if (this.editUserForm.controls['userNotes'].value !== this.initialValues.userNotes) this.changeNote()
      if (changePlan || changePrice) this.changePlan(addSms)
      if (addSms && !changePlan) this.addSmsPack()
      if (!!this.editUserForm.controls['userPassword'].value) this.changePassword()
      if (this.editUserForm.controls['transactionLimit'].value !== this.initialValues.transactionLimit) this.updateTransactionLimit()
      if (this.editUserForm.controls['payoutLimit'].value !== this.initialValues.payoutLimit) this.updatePayoutLimit()
    }
  }

  discardChanges() {
    this.editUserForm.patchValue(this.initialValues)
    this.editUserForm.controls['plan'].setValue(this.initialValues.plan)
  }

  createReplica() {
    const data = { uid: this.uid || '' }
    this.backendSrv.createReplicaUser(data).subscribe(_ => this.backToUser())
  }

  deleteBusiness() {
    const data = { bid: this.bid || '' }
    this.backendSrv.deleteBusiness(data).subscribe(_ => this.backToUser())
  }

  reviveBusiness() {
    const data = { bid: this.bid || '' }
    this.backendSrv.reviveBusiness(data).subscribe(_ => this.backToUser())
  }

  backToUser() { this.router.navigate(['admin', 'view', 'user', this.uid]) }

  private processUserInfo(userInfo: any) {
    let activePlan: any
    userInfo.subscriptions.forEach((s: any) => {
      if (s.active_sub) {
        if (s.active_sub === "yes") {
          s.price = parseInt(s.price.split(' ')[0])
          activePlan = s
        }
        if (s.active_sub.data && s.active_sub.data[0] === 1) {
          s.price = s.price / 100
          activePlan = s
        }
      }
    })
    this.isLiveEnv = this.backendSrv.env === "Live"
    this.isBlocked = userInfo.userData[0].is_blocked == 1
    this.isDeleted = !!userInfo.businessData[0].deleted
    this.backendSrv.getCurrencyList().subscribe((list: any) => this.currencyList = list)
    this.backendSrv.getPlans().subscribe((plans: any) => {
      this.plans = plans
      plans.forEach((plan: any) => {
        if (plan.name === activePlan.plan_name) {
          this.initialValues.plan = plan.id
          this.editUserForm.controls['plan'].patchValue(plan.id)
        }
      });
      this.backendSrv.getSmsPacks().subscribe((packs: any) => this.smsPack = packs)
      this.initialValues.email = userInfo.userData[0].email
      this.initialValues.transactionLimit = userInfo.businessData[0].transaction_limit
      this.initialValues.payoutLimit = userInfo.businessData[0].max_payout
      this.signInMethod = this.signInMetodParser(userInfo.userData[0]).type
      this.initialValues.userNotes = userInfo.userData[0].notes
      this.initialValues.price = activePlan.price
      this.initialValues.expiryDate = new Date(userInfo.userData[0].expiry_date.split(';')[0]) ? new Date(userInfo.userData[0].expiry_date.split(';')[0]) : new Date(userInfo.userData[0].expiry_date.split('T')[0])
      this.monthlyReminders += userInfo.userData[0].used_sms + '/' + userInfo.userData[0].total_sms + ' (Used/Total)'
      this.editUserForm.patchValue(this.initialValues)
      this.userName = userInfo.userData[0].name
      this.userRoleOwner = userInfo.userData[0].role === "Account Owner"
      this.bid = userInfo.userData[0].bid
      this.editUserForm.get('currency')?.setValue(activePlan.currency)
      this.editUserForm.get('price')?.setValue(activePlan.price)
    })
  }

  private signInMetodParser(ud: any) {
    let signInType = "Email"
    let signIn = { type: signInType, date: '' }
    if (!!ud.login_email) signIn.date = ud.login_email
    if (!!ud.login_apple) signIn = { "type": signInType += ", Apple", "date": ud.login_apple }
    if (!!ud.login_facebook) signIn = { "type": signInType += ", Facebook", "date": ud.login_facebook }
    if (!!ud.firebase_user_id) signIn = { "type": signInType += ", Google", "date": ud.login_email }
    return signIn
  }

  private changeEmail() {
    const data = {
      uid: this.uid || '',
      email: this.editUserForm.controls['email'].value
    }
    this.backendSrv.changeEmail(data).subscribe(_ => location.reload())
  }

  private changePassword() {
    const data = {
      uid: this.uid || '',
      password: this.editUserForm.controls['userPassword'].value
    }
    this.backendSrv.changePassword(data).subscribe(_ => location.reload())
  }

  private changeNote() {
    const data = {
      uid: this.uid || '',
      note: this.editUserForm.controls['userNotes'].value
    }
    this.backendSrv.insertAdminNote(data).subscribe(_ => location.reload())
  }

  private changePlan(addSms = false) {
    const data = {
      uid: this.uid || '',
      pid: this.editUserForm.controls['plan'].value,
      expiryDate: this.editUserForm.controls['expiryDate'].value.toISOString(),
      price: this.editUserForm.controls['price'].value,
      currency: this.editUserForm.controls['currency'].value
    }
    if (this.editUserForm.controls['plan'].value !== this.initialValues.plan || this.editUserForm.controls['price'].value !== this.initialValues.price) this.backendSrv.changePlan(data).subscribe(_ => {
      if (addSms) this.addSmsPack()
      location.reload()
    })
    else this.backendSrv.changeExpiration(data).subscribe(_ => location.reload())
  }

  private addSmsPack() {
    const data = {
      uid: this.uid || '',
      smsPack: this.editUserForm.controls['pack'].value
    }
    this.backendSrv.addSmsPack(data).subscribe(_ => location.reload())
  }

  private updateTransactionLimit(){
    const data = {
      bid: this.bid || '',
      transactionLimit: this.editUserForm.controls['transactionLimit'].value
    }
    this.backendSrv.updateTransactionLimit(data).subscribe()
  }

  private updatePayoutLimit(){
    const data = {
      bid: this.bid || '',
      payoutLimit: this.editUserForm.controls['payoutLimit'].value
    }
    this.backendSrv.updatePayoutLimit(data).subscribe()
  }
}

function sortObjKeys(unsorted: any) {
  return Object.keys(unsorted).sort().reduce(
    (obj: any, key: any) => {
      obj[key] = unsorted[key];
      return obj;
    }, {})
}
