import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-name-prompt',
  templateUrl: './filter-name-prompt.component.html',
  styleUrls: ['./filter-name-prompt.component.sass']
})
export class FilterNamePromptComponent {
  filterName = ''
  constructor(
    private dialogRef: MatDialogRef<FilterNamePromptComponent>
  ) { }

  onNoClick(): void { this.dialogRef.close() }
}
