import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements HttpInterceptor {

  constructor(public loaderService: LoaderS) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeout = setTimeout(() => this.loaderService.isLoading.next(true), 0.4 * 1000)
    const longTimeout = setTimeout(() => this.loaderService.isLongLoading.next(true), 30 * 1000)

    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.isLoading.next(false);
        this.loaderService.isLongLoading.next(false);
        clearTimeout(timeout)
        clearTimeout(longTimeout)
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoaderS {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLongLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}