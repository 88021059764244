<div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <div fxFlex fxFlexOffset="7">
    <h3>{{userName ? userName+"'s" : ""}} Clients</h3>
  </div>
  <div class="actionBar">
    <div fxFlexFill fxLayoutAlign="center center">
      <button mat-raised-button class="actionButton mat-elevation-z10" color="secondary" (click)="backToUser()">
        Back To User
      </button>
      <mat-form-field fxFlex="20" fxFlexOffset="5">
        <input matInput type="text" id="filter-text-box" placeholder="Search all fields" [(ngModel)]="searchValue">
      </mat-form-field>
      <div fxFlex fxFlexOffset="5">
        <span>{{visibleRows + ' rows'}}</span>
      </div>
      <button mat-raised-button fxFlexOffset="3" class="actionButton mat-elevation-z10" color="secondary"
        (click)="goToAppointments()">
        Appointments
      </button>
      <button mat-raised-button fxFlexOffset="1" class="actionButton mat-elevation-z10" color="secondary"
        (click)="goToMassMessages()">
        Mass Messages
      </button>
      <button mat-raised-button fxFlexOffset="1" class="actionButton mat-elevation-z10" color="accent"
        (click)="resetAllFilters()">
        Reset Filters
      </button>
      <button mat-raised-button fxFlexOffset="1" class="actionButton mat-elevation-z10" color="accent"
        (click)="getUserClients()">
        Refresh Data
      </button>
      <button mat-raised-button fxFlexOffset="1" class="actionButton mat-elevation-z10" color="primary"
        (click)="onBtnExport()">
        Download CSV
      </button>
    </div>
  </div>
  <ag-grid-angular fxFlex="auto" fxFlexOffset="1" class="ag-theme-material"
    (rowDataChanged)="countDisplayedRows($event)" (filterChanged)="countDisplayedRows($event)"
    [quickFilterText]="searchValue" [rowData]="rowData" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions">
  </ag-grid-angular>
</div>