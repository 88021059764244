<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title fxLayoutAlign="start center">
      <span>Business Data</span>
    </mat-panel-title>
    <mat-panel-description>
      <span fxFlex='15'> <span class="bolderFont">Booking Link: </span> </span>
      <span fxFlex='45' fxFlexOffset="30">
        <a [href]="makeBookingLink(businessData.booking_link)" target="_blank" class="linkAppointfixBlue">
          {{makeBookingLink(businessData.booking_link)}}</a>
      </span>
      <mat-icon>business</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <mat-divider class="matDividerAppointfixBlue"></mat-divider>
  <div fxLayout="column">
    <span fxFlex="100" fxFlexOffset="1"> <span fxFlex="34" class="bolderFont">Country </span>
      {{businessData?.user_country_code}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Business name </span>
      {{businessData.business_name ? businessData.business_name: 'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Facebook </span>
      {{businessData.facebook ? businessData.facebook: 'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Instagram </span>
      {{businessData.instagram?businessData.instagram:'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Website </span>
      {{businessData.website?businessData.website:'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Email </span>
      <a [href]="'mailto:'+businessData.business_email" class="linkAppointfixBlue">
        {{businessData.business_email}}</a>
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Phone </span>
      {{businessData.business_phone?businessData.business_phone:'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Address </span>
      <div fxLayout="column" fxFlex>
        <div fxLayout="row">
          <span fxFlex="47.5">{{businessData.address?businessData.address +
            ',':'Address not available, '}} </span>
          <span fxFlex>{{'Region: ' + (businessData.region ? businessData.region:'not available')}}</span>
        </div>
      </div>
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont"> Booking acceptance</span>
      {{businessData.booking_auto_accept.data[0]==1? 'Accept automatically':'Accept or decline
      manually'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Calendar availability</span>
      {{businessData.optimized_schedule.data[0]==1? 'Optimized schedule':'Show all available time
      slot'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Time slots availability</span>
      {{businessData.booking_time_slot == 0? 'According to service duration': 'Every
      '+businessData.booking_time_slot +' minutes'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Booking policy</span>
      <div fxLayout="column" fxFlex>
        <div fxLayout="row">
          <span fxFlex="47.5" *ngIf="businessData.earliest_amount">
            Up to {{businessData.earliest_amount}}
            {{businessData.earliest_amount>1 ?
            businessData.earliest_unit.toLowerCase()+'s':businessData.earliest_unit.toLowerCase()}}
            before start time.
          </span>
          <span fxFlex="47.5" *ngIf="!businessData.earliest_amount">
            Anytime before start time.
          </span>
          <span fxFlex *ngIf="businessData.latest_amount">
            Up to {{businessData.latest_amount}}
            {{businessData.latest_amount>1 ?
            businessData.latest_unit.toLowerCase()+'s':businessData.latest_unit.toLowerCase()}}
            in the future.
          </span>
          <span fxFlex *ngIf="!businessData.latest_amount && !businessData.earliest_amount">
            Not available
          </span>
        </div>
      </div>
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Cancellation policy</span>
      <span *ngIf="businessData.policy == 0">
        Up to {{businessData?.cancel_policy_amount}}
        {{businessData.cancel_policy_amount>1 ?
        businessData?.cancel_policy_unit.toLowerCase()+'s':businessData?.cancel_policy_unit.toLowerCase()}}
        before start time.
      </span>
      <span *ngIf="businessData.policy == 1">Anytime</span>
      <span *ngIf="businessData.policy != 1 && businessData.policy != 0">Never</span>
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont"> Payments</span>
      {{(!businessData.payments_available || businessData.payments_available === 0 ) ? 'N/A':businessData.payments_custom === 1 ? 'CUSTOM ':'STANDARD '}}
      <span *ngIf="businessData.payments_enabled !== null">{{businessData.payments_enabled === 1? '(Enabled)': '(Disabled)'}}</span>
    </span>
    <span fxFlex="100" *ngIf="businessData.deposit_option !== null"> <span fxFlex="34" class="bolderFont"> Deposit</span>
      {{businessData.deposit_option === 2  ? 'NO DEPOSIT':businessData.deposit_option === 0 ? businessData.deposit_percent+'%':businessData.deposit_amount/100 +' '+ businessData.currency }}
    </span>
    <span fxFlex="100" *ngIf="businessData.fee_payer !== null"> <span fxFlex="34" class="bolderFont"> Fee payer</span>
      {{businessData.fee_payer === 0  ? 'Merchant':'Customer' }}
    </span>
    <div fxFlexOffset="2"></div>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Business schedule</span>
      <div fxLayout="column" fxFlex>
        <span *ngFor="let t of businessData?.working_time; index as i" fxLayout="row">
          <span fxFlex="47.5"> {{t.name}}:</span>
          <div *ngIf="t.enabled">
            <span fxFlex *ngFor="let int of t.intervals">{{int.start}} - {{int.end+'; '}} &nbsp;</span>
          </div>
          <span *ngIf="!t.enabled" class="appointfixWarningColor">Closed</span>
        </span>
      </div>
    </span>
  </div>
</mat-expansion-panel>
