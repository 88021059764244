import { combineLatest, fromEvent, MonoTypeOperatorFunction, Observable, pipe, timer } from "rxjs";
import { filter, first, last, map, scan, shareReplay, startWith, switchMapTo, takeWhile, tap } from "rxjs/operators";


export const createOnlineObservable = () => {
  return combineLatest([
    fromEvent(window, 'online').pipe(startWith(null)),
    fromEvent(window, 'offline').pipe(startWith(null)),
  ]).pipe(
    map(() => navigator.onLine),
    shareReplay(),
  );
}

export const debugObs = <T>(tag: string): MonoTypeOperatorFunction<T> => {
  return tap({
    next: v => console.log(`%c[${tag}: Next]`, "background: #009688; color: #fff; padding: 3px; font-size: 9px;", v),
    error: e => console.log(`%[${tag}: Error]`, "background: #E91E63; color: #fff; padding: 3px; font-size: 9px;", e),
    complete: () => console.log(`%c[${tag}]: Complete`, "background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;")
  })
}

export const poll = <T>(pollInterval: number, initialDelay = 0): MonoTypeOperatorFunction<T> => {
  return source$ => timer(initialDelay, pollInterval).pipe(switchMapTo(source$));
}

export const pollWhile = <T>(
  pollInterval: number,
  isPollingActive: (res: T) => boolean,
  maxAttempts = Infinity,
  emitOnlyLast = false
): MonoTypeOperatorFunction<T> => {
  return source$ => {
    const poll$ = timer(0, pollInterval).pipe(
      scan(attempts => ++attempts, 0),
      tap(attemptsGuardFactory(maxAttempts)),
      switchMapTo(source$),
      takeWhile(isPollingActive, true)
    )

    return emitOnlyLast ? poll$.pipe(last()) : poll$;
  }
}

export const firstTruthy = <T>(): MonoTypeOperatorFunction<T> => { return pipe(first(v => Boolean(v))) }

export const filterNil = () => { return filter(value => value !== undefined && value !== null) }

function attemptsGuardFactory(maxAttempts: number) {
  return (attemptsCount: number) => {
    if (attemptsCount > maxAttempts) {
      throw new Error("Exceeded maxAttempts");
    }
  }
}