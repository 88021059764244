import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, skip } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { BackendService } from '../../services/backend.service';
import { ColorSchemeService } from '../../services/color-scheme.service';
import { LoaderS } from '../../interceptors/loader-interceptor.service';
import { slider, transformer, fader, stepper } from '../../../route-animations';
import { SnackbarMessagingService } from '../../services/snackbar-messaging.service';
import { createOnlineObservable } from '../../RxjsUtils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  animations: [
    fader,
    // slider,
    // transformer,
    // stepper
  ],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  onlineStatus$ = createOnlineObservable()
  toggleControl = new FormControl(this.colorSchemeService.currentActive() === 'dark');
  environments = ["Live", "Test", "Development", "Replica"]
  // environments = ["Development"]
  user: any
  menuItems: any = [
    {
      label: 'Users Data',
      icon: 'people',
      route: 'users',
      showOnMobile: false,
      showOnTablet: true,
      showOnDesktop: true,
    },
    {
      label: 'Push Notifications',
      icon: 'notifications_active',
      route: 'push-notifications',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
    {
      label: 'Roles',
      icon: 'manage_accounts',
      route: 'roles',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
  ];
  showEnv: boolean = true;

  constructor(
    public router: Router,
    public auth: AuthService,
    public backendSrv: BackendService,
    public loaderService: LoaderS,
    public colorSchemeService: ColorSchemeService,
    private msgSrv: SnackbarMessagingService,

  ) {
    this.auth.user$.subscribe(user => {
      this.user = user
      this.menuItems[1].showOnDesktop = this.auth.canEdit(this.user)
      this.menuItems.at(-1).showOnDesktop = this.auth.canAdmin(this.user)
    })
    this.onlineStatus$.pipe(skip(1)).subscribe(onlineStatus =>
      onlineStatus ?
        this.msgSrv.openSnackBarMessage({ title: 'Online', description: `Internet is back. \n Data requested in offline mode is being delivered now.` }, 'info', 4) :
        this.msgSrv.openSnackBarMessage({ title: 'Offline', description: `Lost internet connection. You are in offline mode.` }, 'warning', 4)
    )
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(_ => this.showEnv = this.router.url.indexOf('/user/') > 0 || this.router.url.indexOf('/roles') > 0 ? false : true);
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges
      .subscribe(darkTheme => darkTheme ?
        this.colorSchemeService.update('dark') :
        this.colorSchemeService.update('light'));
  }

  setEnvironment(env: string) {
    this.backendSrv.defaultEnvironment.next(env)
    localStorage.setItem('ENVIRONMENT', env);
  }

  navigateTo(route: string) { this.router.navigate(['admin', route]) }

  prepareRoute(outlet: RouterOutlet) { return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']; }

}
