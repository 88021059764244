<div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <div class="actionBar" fxFlexOffset="7.5" fxFlex="initial" *ngIf="userInfo && userInfo.userData.length > 0">
    <div fxFlexFill fxLayoutAlign="start center">
      <button mat-raised-button color="accent" (click)="accordion.openAll()">Expand All</button>
      <button mat-raised-button color="accent" (click)="accordion.closeAll()">Collapse All</button>
      <button mat-raised-button *ngIf="auth.canEdit(user)" fxFlexOffset="7" class="actionButton mat-elevation-z10"
        color="primary" (click)='navigateToEditUser()'>Edit User</button>
      <div class="actionButton" fxFlexOffset="7" *ngIf="!auth.canEdit(user)"></div>
      <button mat-raised-button fxFlexOffset="7" class="mat-elevation-z10" color="secondary"
        (click)='navigateToAppointments()'>Appointments</button>
      <button mat-raised-button fxFlexOffset="1" class="actionButton mat-elevation-z10" color="secondary"
        (click)='navigateToClients()'>Clients</button>
      <button mat-raised-button fxFlexOffset="1" class="mat-elevation-z10" color="secondary"
        (click)='navigateToMassMessages()'>Mass Messages</button>
      <mat-form-field fxFlex fxFlexOffset="5">
        <input matInput type="text" id="filter-text-box" placeholder="Search grid entries" [(ngModel)]="searchValue">
      </mat-form-field>
    </div>
  </div>
  <div class="accordionContainer" fxFlexOffset="3" fxFlex *ngIf="userInfo && userInfo.userData.length > 0">
    <mat-accordion multi color="primary" class="accordion-headers-align mat-elevation-z10" *ngIf="userInfo">
      <!-- User Details -->
      <user-details-panel [userData]="userInfo.userData[0]" [userSettings]="userInfo.userSettings[0]"
        [timezone]="userInfo.businessData[0].timezone">
      </user-details-panel>
      <!-- Business data -->
      <business-data-panel [businessData]="userInfo.businessData[0]"></business-data-panel>
      <!-- Settings -->
      <user-settings-panel [userSettings]="userInfo.userSettings[0]" [businessData]="userInfo.businessData[0]"
        [messages]="userInfo.messages"></user-settings-panel>
      <!-- Staff -->
      <mat-expansion-panel *ngIf="staffRowData.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center">
            <span>Staff</span>
          </mat-panel-title>
          <mat-panel-description>
            <span fxFlex='30'> <span class="bolderFont">Number Of Staffs: </span>
              {{staffRowData.length}}</span>
            <span fxFlex='30'> <span class="bolderFont">Matching Search: </span>
              {{visibleRows.Staff}}</span>
            <mat-icon>view_list</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="matDividerAppointfixBlue"></mat-divider>
        <div>
          <ag-grid-angular fxFlex="auto" class="ag-theme-material gridSize" [gridOptions]="defaultGridOptions"
            [rowData]="staffRowData" (rowDataChanged)="countDisplayedRows($event,'Staff')" [columnDefs]="staffColDef"
            [frameworkComponents]="frameworkComponents" (filterChanged)="countDisplayedRows($event,'Staff')"
            [rowClassRules]="rowClassCurrentUser" [quickFilterText]="searchValue">
          </ag-grid-angular>
        </div>
      </mat-expansion-panel>
      <!-- Devices -->
      <mat-expansion-panel *ngIf="devicesRowData.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center">
            <span>Devices</span>
          </mat-panel-title>
          <mat-panel-description>
            <span fxFlex='30'> <span class="bolderFont">Number Of Devices: </span>
              {{devicesRowData.length}}</span>
            <span fxFlex='30'> <span class="bolderFont">Matching Search: </span>
              {{visibleRows.Devices}}</span>
            <mat-icon>view_list</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="matDividerAppointfixBlue"></mat-divider>
        <div>
          <ag-grid-angular fxFlex="auto" class="ag-theme-material gridSize" [gridOptions]="defaultGridOptions"
            [rowData]="devicesRowData" (rowDataChanged)="countDisplayedRows($event,'Devices')"
            [columnDefs]="devicesColDef" [frameworkComponents]="frameworkComponents"
            [rowClassRules]="rowClassCurrentUser" (filterChanged)="countDisplayedRows($event,'Devices')"
            [quickFilterText]="searchValue">
          </ag-grid-angular>
        </div>
      </mat-expansion-panel>
      <!-- Services -->
      <mat-expansion-panel *ngIf="userInfo.services.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center">
            <span>Services</span>
          </mat-panel-title>
          <mat-panel-description>
            <span fxFlex='30'> <span class="bolderFont">Number Of Services: </span>
              {{servicesRowData.length}}</span>
            <span fxFlex='30'> <span class="bolderFont">Matching Search: </span>
              {{visibleRows.Services}}</span>
            <mat-icon>view_list</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="matDividerAppointfixBlue"></mat-divider>
        <div>
          <ag-grid-angular fxFlex="auto" class="ag-theme-material gridSize" [columnDefs]="servicesColDef"
            [gridOptions]="defaultGridOptions" [rowData]="servicesRowData"
            (rowDataChanged)="countDisplayedRows($event,'Services')" [rowClassRules]="rowClassCurrentUser"
            (filterChanged)="countDisplayedRows($event,'Services')" [quickFilterText]="searchValue">
          </ag-grid-angular>
        </div>
      </mat-expansion-panel>
      <!-- Subscriptions -->
      <mat-expansion-panel *ngIf="userInfo.subscriptions.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center">
            <span>Subscriptions</span>
          </mat-panel-title>
          <mat-panel-description>
            <span fxFlex='30'> <span class="bolderFont">Number Of Subscriptions: </span>
              {{subscriptionsRowData.length}}</span>
            <span fxFlex='30'> <span class="bolderFont">Matching Search: </span>
              {{visibleRows.Subscriptions}}</span>
            <mat-icon>view_list</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="matDividerAppointfixBlue"></mat-divider>
        <div>
          <ag-grid-angular fxFlex="auto" class="ag-theme-material gridSize" [columnDefs]="subscriptionsColDef"
            [gridOptions]="defaultGridOptions" [rowData]="subscriptionsRowData"
            (rowDataChanged)="countDisplayedRows($event, 'Subscriptions')" [frameworkComponents]="frameworkComponents"
            (gridReady)="sortGrid($event, 'purchase_date', 'desc')"
            (filterChanged)="countDisplayedRows($event, 'Subscriptions')" [quickFilterText]="searchValue">
          </ag-grid-angular>
        </div>
      </mat-expansion-panel>
      <!-- Purchases -->
      <mat-expansion-panel *ngIf="userInfo.subscriptions.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center">
            <span>Purchases </span>

            <button mat-icon-button color="primary" (click)="navigateToRevenueCat()">
              <mat-icon>payments</mat-icon>
            </button>
          </mat-panel-title>
          <mat-panel-description>
            <span fxFlex='30'> <span class="bolderFont">Number Of Purchases: </span>
              {{purchasesRowData.length}}</span>
            <span fxFlex='30'> <span class="bolderFont">Matching Search: </span>
              {{visibleRows.Purchases}}</span>
            <mat-icon>view_list</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="matDividerAppointfixBlue"></mat-divider>
        <div>
          <ag-grid-angular fxFlex="auto" class="ag-theme-material gridSize" [columnDefs]="purchasesColDef"
            [gridOptions]="defaultGridOptions" [rowData]="purchasesRowData"
            (rowDataChanged)="countDisplayedRows($event, 'Purchases')" [frameworkComponents]="frameworkComponents"
            (gridReady)="sortGrid($event, 'purchase_date', 'desc')"
            (filterChanged)="countDisplayedRows($event, 'Purchases')" [quickFilterText]="searchValue">
          </ag-grid-angular>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxLayoutAlign="center center" class="accordionContainer" *ngIf="userInfo && userInfo.userData.length == 0">
    <h1 class="appointfixWarningColor">Please check environment</h1>
  </div>
</div>
