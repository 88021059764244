import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { IUser } from '../_shared/interfaces/mobi-user';
import { AuthService } from '../_shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersStoreService {

  private usersFilterModelsRef!: any

  private userFilters = new BehaviorSubject<any>(false);
  userFilters$ = this.userFilters.asObservable().pipe(shareReplay(1));

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
  ) {
    this.auth.user$.pipe(take(1)).subscribe(user => {
      this.usersFilterModelsRef = this.afs.doc<IUser>(`users/${user.uid}`).collection('userFilterModels');
      this.usersFilterModelsRef.valueChanges({ idField: 'filterName' }).pipe(shareReplay(1))
        .subscribe((val: any) => {
          let data: any[] = []
          val.forEach((filter: any) => {
            const fName = filter.filterName
            delete filter.filterName;
            data.push({ filterName: fName, filterModel: filter })
          });

          this.userFilters.next(data)
        })
    })
  }

  updateFilters(filterName: string, filterValues: any) {
    this.usersFilterModelsRef.doc(filterName).set(filterValues)
  }

  deleteFilter(filterName: string) {
    this.usersFilterModelsRef.doc(filterName).delete()
  }
}

