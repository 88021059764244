<div fxLayout="column" fxFlexFill>
  <div fxFlexOffset="7" fxFlex="initial">
    <div fxFlexFill fxLayoutAlign="center center">
      <mat-form-field fxFlex class="search">
        <input matInput type="text" id="filter-text-box" placeholder="Search all fields" [(ngModel)]="searchValue"
          (ngModelChange)='this.searchValueChanged.next($event)'>
      </mat-form-field>
      <div fxFlex="10" fxFlexOffset="7" fxLayout="column" fxLayoutAlign="center center">
        <span fxFlex matTooltipPosition="above" matTooltipClass="filter-tooltip" matTooltip="Updated: {{ (updatedAt$ | async)?.date  | date: 'dd MMM H:mm:ss' }} 
          With: {{(updatedAt$ | async)?.env}} data">
          {{visibleRows + ' rows'}}
          <mat-icon class="helpIcon">help_outline</mat-icon>
        </span>
      </div>
      <mat-form-field class="appointment-input" fxFlex="8" fxFlexOffset="5">
        <input matInput type="number" placeholder="Appointments" [(ngModel)]="subNo">
      </mat-form-field>
      <mat-form-field class="appointment-input" fxFlex="8">
        <input matInput type="number" placeholder="Last n days" [(ngModel)]="nDays">
      </mat-form-field>
      <mat-checkbox [checked]="autoRefresh" (change)="setAutoRefresh($event)" matTooltipPosition="above"
        matTooltipClass="filter-tooltip" matTooltip="Refresh data on environment change" color="primary"
        fxFlexOffset="2" labelPosition="before">
      </mat-checkbox>
      <button mat-raised-button fxFlexOffset="1" class="action-button mat-elevation-z10" color="secondary"
        (click)="refreshData()" [disabled]="!(online$ | async)">
        Refresh Data
      </button>
      <button mat-raised-button fxFlexOffset="1" class="action-button mat-elevation-z10" color="primary"
        (click)="onBtnExport()">
        Download CSV
      </button>
    </div>
  </div>
  <div fxFlexOffset="1" class="filters-bar">
    <div fxFlexFill fxLayout="row">
      <div fxLayoutAlign="start center">
        <button mat-icon-button matTooltip="Saved Configurations" [matMenuTriggerFor]="filterConfigMenu"
          matTooltipPosition="below" matTooltipClass="filter-tooltip" color="accent">
          <mat-icon>restore</mat-icon>
        </button>
        <mat-menu #filterConfigMenu="matMenu" xPosition="after" fxFlex>
          <div mat-menu-item *ngFor="let filter of storeFilters">
            <button mat-button fxFlex class="filter-config-button" (click)="restoreFilters(filter)">
              {{filter.filterName}}
            </button>
            <button mat-button fxFlex="3" class="filter-config-button" (click)="removeFilterConfig(filter.filterName)">
              <mat-icon color="warn">delete_outline</mat-icon>
            </button>
          </div>
        </mat-menu>
        <button mat-icon-button matTooltip="Reset All Filters" matTooltipPosition="below"
          matTooltipClass="filter-tooltip" color="warn" (click)="resetAllFilters()">
          <mat-icon>disabled_by_default</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Save Filters Configuration" matTooltipPosition="below"
          matTooltipClass="filter-tooltip" color="primary" (click)="saveFilters()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
      <div fxFlex fxFlexOffset="2">
        <mat-chip-list>
          <mat-chip *ngIf="!activeFiltersModel.length" class="mat-elevation-z5" color="accent" selected>No Active Column
            Filters</mat-chip>
          <mat-chip color="warn" selected class="mat-elevation-z5" (removed)="removeChipFilter(filter.field)"
            *ngFor="let filter of activeFiltersModel" matTooltip="{{filterTooltip(filter.field)}}"
            matTooltipPosition="below" matTooltipClass="filter-tooltip">
            {{filter.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div fxLayoutAlign="end center">
        <button mat-icon-button matTooltip="Set Default Column View" matTooltipPosition="below"
          matTooltipClass="filter-tooltip" color="primary" (click)="saveDefaultColDef()">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Reset Columns to Default Setup" matTooltipPosition="below"
          matTooltipClass="filter-tooltip" color="warn" (click)="clearLocalStorage()">
          <mat-icon>disabled_by_default</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Show/Hide Columns" matTooltipPosition="below"
          matTooltipClass="filter-tooltip" color="accent" [matMenuTriggerFor]="columnsConfigMenu">
          <mat-icon>apps</mat-icon>
        </button>
        <mat-menu #columnsConfigMenu="matMenu" xPosition="before" fxFlex>
          <mat-selection-list color="primary">
            <div *ngFor="let col of columnDefs" (click)="$event.stopPropagation()">
              <mat-list-option [checkboxPosition]="'before'" [value]="col.field" *ngIf="col.headerName"
                [selected]="!col.hide" (click)="toggleColumnVisibility(col.field,col.hide); col.hide = !col.hide">
                {{col.headerName}}
              </mat-list-option>
            </div>
          </mat-selection-list>
        </mat-menu>
      </div>
    </div>

  </div>
  <ag-grid-angular fxFlex="auto" fxFlexOffset="2" class="ag-theme-material"
    (rowDataChanged)="countDisplayedRows($event)" (filterChanged)="countDisplayedRows($event)"
    [quickFilterText]="debouncedSearchValue" [rowData]="rowData" [overlayLoadingTemplate]="loadingTemplate"
    [overlayNoRowsTemplate]="loadingTemplate" [frameworkComponents]="frameworkComponents"
    (gridReady)="onGridReady($event)" [gridOptions]="gridOptions">
  </ag-grid-angular>
</div>