<div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <div fxFlexOffset="7" class="fullWidth">
    <div fxFlexFill fxLayoutAlign="start center">
      <div fxFlex>
        <button mat-raised-button class="actionBarButton mat-elevation-z10" color="primary" (click)="submitChanges()"
          [disabled]="!pushNotifForm.valid || pushNotifForm.pristine">
          Submit
        </button>
        <button fxFlexOffset="1" mat-raised-button class="actionBarButton mat-elevation-z10" color="warn"
          (click)="pushNotifForm.reset()" [disabled]="pushNotifForm.pristine">
          Discard
        </button>
      </div>
    </div>
  </div>
  <div fxFlex class="fullWidth" fxFlexOffset="2">
    <div fxLayout="column">
      <mat-divider class="matDividerAppointfixBlue"></mat-divider>
      <h2 fxLayoutAlign="center center" fxFlexOffset="2">Push Target</h2>
      <form [formGroup]="pushNotifForm" fxFlex fxLayout="column">
        <div fxLayout="row" fxFlexOffset="1">
          <mat-checkbox [checked]="true" color="primary" formControlName="consented">Consented to marketing push
            notifications
          </mat-checkbox>
          <mat-checkbox fxFlexOffset="3" color="primary" formControlName="android">Android
          </mat-checkbox>
          <mat-checkbox fxFlexOffset="3" color="primary" formControlName="ios">iOS
          </mat-checkbox>
        </div>
        <div fxLayout="row" fxFlexOffset="3">
          <mat-form-field fxFlex appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput type="email" name="email" formControlName="email">
          </mat-form-field>
          <mat-form-field fxFlex="15" appearance="fill" fxFlexOffset="3">
            <mat-label>App Version Operator</mat-label>
            <mat-select formControlName="appVersionSelect" fxFlex>
              <mat-option *ngFor="let operator of appVersionSelectOperatorMap" [value]="operator.value">
                {{operator.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15" appearance="fill">
            <mat-label>Version</mat-label>
            <input fxFlex matInput type="text" name="appVersion" formControlName="appVersion"
              class="inputHeightRestrain">
          </mat-form-field>
          <mat-form-field fxFlex appearance="fill" fxFlexOffset="3">
            <mat-label>Plans</mat-label>
            <mat-select formControlName="plan" multiple>
              <mat-option *ngFor="let plan of plans" [value]="plan.id">
                {{plan.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-divider class="matDividerAppointfixBlue" fxFlexOffset="1"></mat-divider>
        <div fxFlexOffset="2"></div>
        <h2 fxLayoutAlign="center center">Push Content</h2>
        <div fxLayout="column">
          <div fxLayout="row" fxFlexOffset="2">
            <div fxFlex fxLayout="column">
              <mat-form-field fxFlex appearance="fill">
                <mat-label>Notification Title</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="9" matInput
                  formControlName="notifTitle" required></textarea>
              </mat-form-field>
              <span class="formError" *ngIf="pushNotifForm.controls.notifTitle.errors?.required"> Field required</span>
            </div>
            <div fxFlex fxLayout="column" fxFlexOffset="3">
              <mat-form-field fxFlex appearance="fill">
                <mat-label>Notification Content</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="9" matInput
                  formControlName="notifContent" required></textarea>
              </mat-form-field>
              <span class="formError" *ngIf="pushNotifForm.controls.notifContent.errors?.required"> Field
                required</span>
            </div>
          </div>
          <div fxLayout="row" fxFlexOffset="2">
            <mat-form-field fxFlex="48.5" appearance="fill">
              <mat-label>URL (Firebase requirement - it must start with 'https://')</mat-label>
              <input fxFlex matInput type="url" pattern="https://.*" formControlName="url" class="inputHeightRestrain">
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>