export const environment = {
  production: false,
  // production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCGXM5zFbAQgsrCqvjzosZa9zAyEu8BsgI",
    authDomain: "appointfix-admin-area.firebaseapp.com",
    projectId: "appointfix-admin-area",
    storageBucket: "appointfix-admin-area.appspot.com",
    messagingSenderId: "17157522775",
    appId: "1:17157522775:web:68325c871e8bef67ecaa12"
  },
  API_URL: `https://admin-dev.heygoldie.com/admin-area/api/`
  // API_URL: `https://localhost:8000/admin-area/api/`
};
