import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '../_shared/services/auth.service';
import { ColorSchemeService } from '../_shared/services/color-scheme.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  user: any;
  validEmail: boolean = false
  toggleControl = new FormControl(this.colorSchemeService.currentActive() === 'dark');

  constructor(
    public auth: AuthService,
    private colorSchemeService: ColorSchemeService
  ) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      this.user = user
      this.validEmail = user.email.indexOf('@appointfix.com') > 0
    })
    this.toggleControl.valueChanges.subscribe((darkTheme) => {
      darkTheme ? this.colorSchemeService.update('dark') : this.colorSchemeService.update('light')
    });
  }

  signOut(): void { this.auth.signOut() }

}
