import { ColDef } from "ag-grid-community";
import * as moment from "moment-timezone";


const dayNameMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const defaultColDef: ColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  wrapText: true,
  autoHeight: true,
  cellStyle: {
    'white-space': 'normal',
    'word-break': 'break-word',
    'line-height': '25px',
    'font-size': '13px',
    'min-height': '100px',
  },
  filterParams: { newRowsAction: 'keep' },
}

export const blanksFilterOptionsNumber = [
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
  {
    displayKey: 'blanks',
    displayName: 'Blanks',
    test: (filterValue: any, cellValue: any) => (cellValue == undefined || cellValue == null || cellValue == ''),
    hideFilterInput: true,
  },
  {
    displayKey: 'notBlanks',
    displayName: 'Not blanks',
    test: (filterValue: any, cellValue: any) => (cellValue !== undefined && cellValue !== null && cellValue !== ''),
    hideFilterInput: true,
  },
]

export const blanksFilterOptionsText = [
  'contains',
  'notContains',
  'equals',
  'notEqual',
  'startsWith',
  'endsWith',
  {
    displayKey: 'blanks',
    displayName: 'Blanks',
    test: (filterValue: any, cellValue: any) => (cellValue == undefined || cellValue == null || cellValue == ''),
    hideFilterInput: true,
  },
  {
    displayKey: 'notBlanks',
    displayName: 'Not blanks',
    test: (filterValue: any, cellValue: any) => (cellValue !== undefined && cellValue !== null && cellValue !== ''),
    hideFilterInput: true,
  },
];

export const momentDateFilterParams = {
  comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
    let dateParts = cellValue.split(',')
    let momentFormat = moment(dateParts[0], 'dd MMM D YYYY')
    let cellDate = momentFormat.toDate()

    if (cellDate < filterLocalDateAtMidnight) return -1
    if (cellDate > filterLocalDateAtMidnight) return 1
    return 0
  },
  browserDatePicker: true,
  minValidYear: 2015,
}

export const dateComparator = (date1: any, date2: any) => {
  let dateParts1 = date1.split(':')
  let momentFormat1 = moment(dateParts1[0] + ':' + dateParts1[1] + ':' + dateParts1[2], 'dd MMM D YYYY, HH:mm:ss')
  let date1AsDate = momentFormat1.toDate().valueOf()

  let dateParts2 = date2.split(',')
  let momentFormat2 = moment(dateParts2[0] + ':' + dateParts2[1] + ':' + dateParts2[2], 'dd MMM D YYYY, HH:mm:ss')
  let date2AsDate = momentFormat2.toDate().valueOf()

  if (date1AsDate === null && date2AsDate === null) return 0
  if (date1AsDate === null) return -1
  if (date2AsDate === null) return 1
  return date1AsDate - date2AsDate;
}

export const dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
    let dateAsString = cellValue
    let datePart = dateAsString.split('T')
    let dateParts = datePart[0].split('-')
    let cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
    if (cellDate < filterLocalDateAtMidnight) return -1
    if (cellDate > filterLocalDateAtMidnight) return 1
    return 0
  },
  browserDatePicker: true,
  minValidYear: 2015,
}

export const processDateWithBucharestTZ = (dateString: string, timezone: string) => {
  return `${moment(dateString).tz(timezone)
    .format('dd MMM D YYYY; HH:mm:ss')} ${timezone}; 
    ${moment(dateString).tz('Europe/Bucharest').format('HH:mm:ss')} Bucharest`
}

export const processDate = (dateString: string, timezone: string) => {
  return `${moment(dateString).tz(timezone)
    .format('dd MMM D YYYY; HH:mm:ss')} ${timezone}`
}

export const makeWorkingHoursArray = (object: any) => {
  let returnArr: any = []
  dayNameMap.forEach(day => {
    const DAY = day.toLowerCase()
    returnArr.push({
      name: day,
      enabled: object[`${DAY}_enable`] === 1,
      intervals: JSON.parse(object[`${DAY}_intervals`].split('\\').join(''))
    })
  })
  return returnArr
}