<button mat-icon-button *ngIf="showButton" matTooltip="View Instances" matTooltipPosition="right" color="primary"
  (click)="viewInstances()">
  <mat-icon>visibility</mat-icon>
</button>
<div *ngIf="!showButton" matTooltip="Not Recurrent" matTooltipPosition="right">
  <button mat-icon-button disabled color="primary">
    <mat-icon>visibility_off</mat-icon>
  </button>
</div>

