import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_shared/services/backend.service';

@Component({
	selector: 'app-revenue-cat',
	templateUrl: './revenue-cat.component.html',
	styleUrls: ['./revenue-cat.component.sass']
})
export class RevenueCatComponent implements OnInit {

	private uid: string | null = ''
	subscriberInfo: any
	purchases: any

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private backendSrv: BackendService
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.uid = params.get('uid') || ''
			this.backendSrv.getRevenueCatSubscriberInfo(this.uid).subscribe((data: any) => {
				console.log(data.subscriber)
				this.subscriberInfo = JSON.stringify(data.subscriber, null, 2)
			})
			this.backendSrv.userData$.subscribe(userData => {
				if (userData) {
					this.purchases = JSON.stringify(userData.purchases, null, 2);
				}
				else this.navigateToUser()
			})
		})
	}

	navigateToUser() { this.router.navigate(['admin', 'view', 'user', this.uid]) }
}
