import { Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { InstancesModalComponent } from '../instances-modal/instances-modal.component';

@Component({
  selector: 'app-view-instance-cell-button',
  templateUrl: './view-instance-cell-button.component.html',
  styleUrls: ['./view-instance-cell-button.component.sass']
})
export class ViewInstanceCellButtonComponent implements ICellRendererAngularComp {
  showButton: boolean = false
  private params: any;

  constructor(
    private dialog: MatDialog,
    readonly ngZone: NgZone
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.showButton = this.params.node.data.repeat_until_type > 0
  }

  refresh(params?: any): boolean { return true; }

  viewInstances() {
    if (this.params.onClick instanceof Function) {
      this.openInfoModal(this.params.node.data)
    }
  }

  private openInfoModal(data: any) {
    this.ngZone.run(() => {
      this.dialog.open(InstancesModalComponent, {
        data: data,
        maxWidth: '100vw',
        maxHeight: '82vh',
        height: '100%',
        width: '87%',
        panelClass: 'instancesModal'
      })
    })
  }
}
