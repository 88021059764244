import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { BackendService } from '../_shared/services/backend.service';
import { PushInfoPopupComponent } from './push-info-popup/push-info-popup.component';

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PushNotificationsComponent implements OnInit {
  pushNotifForm: FormGroup;
  plans: any = [];
  appVersionSelectOperatorMap = [
    {
      name: 'Equals',
      value: '='
    },
    {
      name: 'Lower than',
      value: '<'
    },
    {
      name: 'Greater than',
      value: '>'
    },
    {
      name: 'Lower than or Equals',
      value: '<='
    },
    {
      name: 'Greater than or Equals',
      value: '>='
    },
  ]

  constructor(
    private backendSrv: BackendService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    readonly ngZone: NgZone
  ) {
    this.pushNotifForm = this.fb.group({
      plan: [],
      appVersionSelect: [],
      appVersion: [],
      email: ['', [Validators.email]],
      consented: [],
      android: [],
      ios: [],
      notifTitle: ['', Validators.required],
      notifContent: ['', Validators.required],
      url: []
    })
  }

  ngOnInit(): void { this.backendSrv.getPlans().pipe(take(1)).subscribe(plans => this.plans = plans) }

  submitChanges() {
    this.backendSrv.pushNotifications(this.pushNotifForm.value).pipe(take(1))
      .subscribe(data => this.openInfoModal(data))
  }

  private openInfoModal(data: any) {
    this.ngZone.run(() => {
      this.dialog.open(PushInfoPopupComponent, {
        data: data,
        position: {
          top: '170px',
        },
        width: '500px'
      })
    })
  }
}
