import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BackendService } from 'src/app/_shared/services/backend.service';

@Component({
  selector: 'app-device-log-cell-button',
  templateUrl: './device-log-cell-button.component.html',
  styleUrls: ['./device-log-cell-button.component.sass']
})
export class DeviceLogCellButtonComponent implements ICellRendererAngularComp {
  params: any;
  showButton: boolean = false
  uid: string | null = ''
  downloadDisabled: boolean = false
  requestDisabled: boolean = false
  did: any;
  logDate: any;

  constructor(
    private backendSrv: BackendService,
  ) { }

  agInit(params: any): void {
    this.params = params
    this.logDate = params.node.data.d_l_created_at
    this.downloadDisabled = params.node.data.ac_send_logs !== null && params.node.data.ac_send_logs === 'false' && this.logDate ? false : true
    this.requestDisabled = !!params.node.data.ac_send_logs
    this.did = params.node.data.d_s_device_id
  }

  requestLog($event: any) {
    if (this.params.onClick instanceof Function) {
      const data = { did: this.did }
      this.backendSrv.requestDeviceLog(data).subscribe()
    }
  }

  downloadLog($event: any) {
    if (this.params.onClick instanceof Function) {
      const data = { did: this.did, logDate: this.logDate }
      const filename = `${this.did}-${this.logDate}.log`
      this.backendSrv.downloadDeviceLog(data).subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename) downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      )
    }
  }

  refresh(params?: any): boolean { return true }
}
