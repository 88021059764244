<h2 mat-dialog-title color="warn">Push Info</h2>
<mat-dialog-content>
  <p>Notifications delivered: {{success | number: '0.0-0' }}</p>
  <p *ngIf="error">With errors: {{error | number: '0.0-0' }}</p>
  <p>Success rate: {{success/(success+(error || 0))*100 | number: '0.0-2'}}%</p>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button class="closeButton mat-elevation-z10" color="primary" (click)="closeDialog()"> OK
  </button>
</mat-dialog-actions>