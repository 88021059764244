import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromEvent, throwError } from 'rxjs';
import { mapTo, retryWhen, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternetInterceptorService implements HttpInterceptor {

  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));

  get isOnline(): boolean { return navigator.onLine }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      retryWhen(errors => {
        return errors.pipe(
          switchMap(err => this.isOnline ? throwError(err) : this.onlineChanges$),
        );
      })
    )
  }
}