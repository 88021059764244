import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-push-info-popup',
  templateUrl: './push-info-popup.component.html',
  styleUrls: ['./push-info-popup.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PushInfoPopupComponent {

  success: number = 0;
  error: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PushInfoPopupComponent>,
  ) {
    data.forEach((el: any) => {
      this.success += el.success
      this.error += el.error;
    });
  }

  closeDialog() {
    this.dialogRef.close('');
  }
}