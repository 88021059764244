<mat-expansion-panel>
  <mat-expansion-panel-header [ngClass]="planCss">
    <mat-panel-title fxLayout="column" fxLayoutAlign="start">
      <span class="bolderFont userName">
        {{userData.name ? userData.name:'Unnamed User'}}</span>
      <span>
        {{userData.role }}</span>
    </mat-panel-title>
    <mat-panel-description>
      <span fxFlex='30'>
        <span class="bolderFont">Email:
          <a [href]="'mailto:'+userData.email">
            {{userData.email}}</a>
        </span>
      </span>
      <span fxFlex='30'> <span class="bolderFont">Plan: </span>
        {{userData.p_name}}</span>
      <mat-icon>account_circle</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div fxLayout="column" fxLayoutAlign="space-between">
    <span fxFlex="100" fxFlexOffset="2"> <span fxFlex="34" class="bolderFont">Business ID </span>
      {{userData.bid}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">State </span>
      {{userData.state}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Profession </span>
      <span *ngIf="userData.profession_name">{{userData.profession_name?.en.singular}}</span>
      <span *ngIf="!userData.profession_name && userData.profession_custom">{{userData.profession_custom}}</span>
      <span *ngIf="!userData.profession_name && !userData.profession_custom">Not
        available</span>
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Phone </span>
      {{userData.phone_number ? userData.phone_number : 'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Plan purchase date </span>
      {{userData.purchase_date}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Plan expiration date </span>
      {{userData.expiry_date}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Monthly cycle reset date </span>
      {{userData.end_date}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Monthly appointments</span>
      {{userData.created_appointments}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Monthly reminders</span>
      {{userData.used_sms}} / {{userData.total_sms}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Admin note</span>
      {{!!userData.notes ? userData.notes : 'Not available'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Sign in method</span>
      {{userData.signInMethod}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Online Booking</span>
      {{userData.online_booking === 1? 'Yes' : 'No'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Push notifications</span>
      {{userSettings.notif_push_enabled === 1? 'Yes' : 'No'}}
    </span>
    <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Email notifications</span>
      {{userSettings.notif_email_enabled === 1? 'Yes' : 'No'}}
    </span>
  </div>
</mat-expansion-panel>
