import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class AuthTokenMiddlewareService implements HttpInterceptor {

  constructor(
    private auth: AngularFireAuth
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      mergeMap((token: any) => {
        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
        }
        return next.handle(request);
      }));
  }
}