import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IAppUsers } from "../interfaces/app-users";
import { IAppointment } from "../interfaces/app-user-appointment";
import { IAppUserInfo } from "../interfaces/app-user-info";
import { IAppUserClient } from "../interfaces/app-user-client";
import { IAppUserMassMessage } from "../interfaces/app-user-mass-message";
import { IAppointmentInstance } from "../interfaces/appointment-instance";
import { IMassMessageRecipient } from "../interfaces/mass-message-recipient";

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private readonly API_URL: string = environment.API_URL

  env: string = localStorage.getItem('ENVIRONMENT') || 'Development';
  defaultEnvironment = new BehaviorSubject(this.env);
  environment$ = this.defaultEnvironment.asObservable().pipe(shareReplay(1));

  private usersData = new BehaviorSubject<IAppUsers[]>([]);
  usersData$ = this.usersData.asObservable().pipe(shareReplay(1), take(1));

  private userData = new BehaviorSubject<any>(false);
  userData$ = this.userData.asObservable().pipe(shareReplay(1), take(1));

  private userDataUpdatedAt = new BehaviorSubject<any>({ date: new Date(), env: this.env });
  userDataUpdatedAt$ = this.userDataUpdatedAt.asObservable().pipe(shareReplay(1));

  constructor(
    private http: HttpClient
  ) {
    this.environment$.subscribe(data => this.env = data)
  }

  getAllUsers(): Observable<IAppUsers[]> {
    return this.http.get<IAppUsers[]>(`${this.API_URL}usersData/${this.env}`).pipe(
      take(1),
      tap(data => {
        this.usersData.next(data)
        this.userDataUpdatedAt.next({ date: new Date, env: this.env })
      }),
    )
  }

  getMinAppointmentNumber(subs: number, days: number): Observable<IAppUsers[]> {
    return this.http.get<IAppUsers[]>(`${this.API_URL}usersData/${this.env}/${subs}/${days}`).pipe(
      take(1),
      tap(data => {
        this.usersData.next(data)
        this.userDataUpdatedAt.next({ date: new Date, env: this.env })
      }),
    )
  }

  getUserInfo(userId: string): Observable<IAppUserInfo> {
    return this.http.get<IAppUserInfo>(`${this.API_URL}userInfo/${this.env}/view/${userId}`)
      .pipe(
        take(1),
        tap(data => this.userData.next(data))
      )
  }

  getBusinessAppointments(businessId: string): Observable<IAppointment[]> { return this.http.get<IAppointment[]>(`${this.API_URL}userInfo/${this.env}/view/${businessId}/appointments`).pipe(take(1)) }

  getAppointmentInstances(appointmentId: string): Observable<IAppointmentInstance[]> { return this.http.get<IAppointmentInstance[]>(`${this.API_URL}userInfo/${this.env}/instances/${appointmentId}`).pipe(take(1)) }

  getUserClients(userId: string): Observable<IAppUserClient[]> { return this.http.get<IAppUserClient[]>(`${this.API_URL}userInfo/${this.env}/view/${userId}/clients`).pipe(take(1)) }

  getUserMassMessages(userId: string): Observable<IAppUserMassMessage[]> { return this.http.get<IAppUserMassMessage[]>(`${this.API_URL}userInfo/${this.env}/view/${userId}/mass-messages`).pipe(take(1)) }

  getMassMessagesRecipients(mmId: string): Observable<IMassMessageRecipient[]> { return this.http.get<IMassMessageRecipient[]>(`${this.API_URL}userInfo/${this.env}/recipients/${mmId}`).pipe(take(1)) }

  // EDIT User
  getPlans() { return this.http.get(`${this.API_URL}userEdit/${this.env}/plans`).pipe(take(1)) }

  getSmsPacks() { return this.http.get(`${this.API_URL}userEdit/${this.env}/smsPacks`).pipe(take(1)) }

  getCurrencyList() { return this.http.get(`${this.API_URL}userEdit/${this.env}/currencyList`).pipe(take(1)) }

  setSubscription(data: { uid: string, sid: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/set-subscription`, data) }

  requestDeviceLog(data: { did: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/log-request`, data) }

  downloadDeviceLog(data: { did: string, logDate: any }) { return this.http.get(`${this.API_URL}userEdit/${this.env}/download-log/${data.did}`, { responseType: 'text' }) }

  changeEmail(data: { uid: string, email: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/change-email`, data) }

  changePassword(data: { uid: string, password: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/change-password`, data) }

  changePlan(data: { uid: string, pid: string, expiryDate: string, price?: number, currency?: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/change-plan`, data) }

  changeExpiration(data: { uid: string, pid: string, expiryDate: string, price?: number, currency?: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/change-expiration`, data) }

  addSmsPack(data: { uid: string, smsPack: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/add-sms`, data) }

  insertAdminNote(data: { uid: string, note: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/set-notes`, data) }

  createReplicaUser(data: { uid: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/create-replica`, data) }

  reviveBusiness(data: { bid: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/revive`, data) }

  deleteBusiness(data: { bid: string }) { return this.http.post(`${this.API_URL}userEdit/${this.env}/delete`, data) }

  // PAYMENTS
  updateTransactionLimit(data:{bid:string, transactionLimit:number}){return this.http.post(`${this.API_URL}payments/${this.env}/updateTransactionLimit`, data)}

  updatePayoutLimit(data:{bid:string, payoutLimit:number}){return this.http.post(`${this.API_URL}payments/${this.env}/updatePayoutLimit`, data)}

  refundAppointment(appointmentId:string){return this.http.post(`${this.API_URL}payments/${this.env}/refundAppointment`, {appointmentId: appointmentId}).pipe(take(1))}

  // PUSH Notif
  pushNotifications(params: any) { return this.http.post(`${this.API_URL}pushNotif/${this.env}`, params) }

  // REVENUE CAT
  getRevenueCatSubscriberInfo(userId: string) { return this.http.get(`${this.API_URL}userInfo/revenueCat/${userId}`).pipe(take(1)) }
}
