import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { IUser } from '../interfaces/mobi-user';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import firebase from 'firebase/app';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;
  private newUserRoles = {
    read: false,
    edit: false,
    pending: true,
    admin: false
  }

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => { return user ? this.afs.doc<IUser>(`users/${user.uid}`).valueChanges() : of(null) }),
      shareReplay(1)
    )
  }

  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async signOut() { await this.afAuth.signOut().then(_ => this.router.navigate(['auth', 'sign-in'])) }

  canRead(user: IUser): boolean {
    const allowed = ['admin', 'edit', 'read']
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: IUser): boolean {
    const allowed = ['admin', 'edit']
    return this.checkAuthorization(user, allowed)
  }

  canAdmin(user: IUser): boolean {
    let allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }

  private updateUserData(user: any) {
    const userRef: AngularFirestoreDocument<IUser> = this.afs.doc(`users/${user.uid}`);
    userRef.get().toPromise().then(dbUser => {
      const data = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        roles: dbUser.data()?.roles || this.newUserRoles,
      }
      this.initDefaultFfilter(user)
      return userRef.set(data, { merge: true })
    })
  }

  private checkAuthorization(user: IUser, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.roles[role]) return true
    }
    return false
  }

  private initDefaultFfilter(user: IUser) {
    const userRef: AngularFirestoreDocument<IUser> = this.afs.doc(`users/${user.uid}`);
    const defaultFilter = userRef.collection('userFilterModels').doc('x Filter Before Page Changed x')
    defaultFilter.get().toPromise().then(filter => {
      return defaultFilter.set(filter.data() || {}, { merge: true })
    })
  }
}

