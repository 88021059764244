import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { GeneralPopupComponent } from '../components/general-popup/general-popup.component';

@Injectable({
  providedIn: 'root'
})

export class ErrorService implements ErrorHandler {
  constructor(
    private dialog: MatDialog,
    readonly ngZone: NgZone
  ) { }

  handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        this.openGeneralModal({
          data: {
            title: 'Info',
            description: `There is no data matching your request.`,
            // description: error.message,
          }
        })
      }
      else {
        this.openGeneralModal({
          data: {
            // title: 'Warning',
            title: error.statusText,
            // description: `Something went wrong. If the problem persists, please contact system administrator.`,
            description: error.message,
          }
        })
      }
    }
    else if (error instanceof TypeError) {
      environment.production ? undefined : console.error(error);
    }
    else {
      environment.production ? undefined : console.error(error);
    }
  }

  private openGeneralModal(data: any) {
    this.ngZone.run(() => {
      this.dialog.open(GeneralPopupComponent, {
        data: data.data,
        position: {
          top: '170px',
        },
        width: '500px'
      })
    })
  }
}
