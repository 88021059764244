<div class="modalWrapper mat-app-background">
  <div mat-dialog-title>
    <span fxFlex>Recipients</span>
    <button mat-icon-button color="warn" class="closeButton" (click)="closeDialog()">
      <mat-icon>wc</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content fxLayout="row" class="modalContent ">
    <ag-grid-angular class="recipientsGrid ag-theme-material" [rowData]="rowData" [gridOptions]="gridOptions">
    </ag-grid-angular>
  </mat-dialog-content>
</div>