import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-general-popup',
  templateUrl: './general-popup.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GeneralPopupComponent {

  title: string;
  description: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GeneralPopupComponent>,
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  closeDialog() {
    this.dialogRef.close('');
  }
}
