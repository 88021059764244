import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-order-cell-button',
  templateUrl: './order-cell-button.component.html',
  styleUrls: ['./order-cell-button.component.sass']
})
export class OrderCellButtonComponent implements ICellRendererAngularComp {
  params: any;
  showButton: boolean = false

  agInit(params: any): void {
    this.params = params;
    this.showButton = this.params.node.data.platform === 1 && this.params.node.data.active !== "yes"
  }

  refresh(params?: any): boolean {
    return true;
  }

  order($event: any) {
    if (this.params.onClick instanceof Function) {
      window.open(
        `https://play.google.com/apps/publish/?dev_acc=00534580799349680443#OrderManagementPlace:orderid=${this.params.node.data.order_id}`,
        '_blank'
      )
      // location.href = `https://play.google.com/apps/publish/?dev_acc=00534580799349680443#OrderManagementPlace:orderid=${this.params.node.data.id}`
    }
  }
}
