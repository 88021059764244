import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment-timezone'
import { IAppointment } from '../_shared/interfaces/app-user-appointment';
import { BackendService } from '../_shared/services/backend.service';
import { ViewInstanceCellButtonComponent } from './view-instance-cell-button/view-instance-cell-button.component';
import { blanksFilterOptionsText, dateComparator, defaultColDef, momentDateFilterParams } from '../_shared/AgGridUtils';
import {TransactionsCellComponent} from "./transactions-cell/transactions-cell.component";

@Component({
	selector: 'app-user-appointments',
	templateUrl: './user-appointments.component.html',
	styleUrls: ['./user-appointments.component.sass']
})
export class UserAppointmentsComponent implements OnInit {

	searchValue: string = ''
	userName: string | null = '';
	userData: any;
	visibleRows: any = false
	rowData: any = []
	currentUser = true
	frameworkComponents = { viewInstancesButton: ViewInstanceCellButtonComponent, transactionsCell: TransactionsCellComponent}
	private grStatusMap = ['Upcoming', 'Sending', 'Sent', 'Failed', 'Action', 'Not sent', 'Delivered', 'N/A']
	private uid: string | null = ''
	private gridApi: any
	private columnDefs: ColDef[] = [
		{
			headerName: 'ID', field: 'id', width: 200, wrapText: true,
			floatingFilter: true, suppressMenu: true
		},
		{
			headerName: 'User IDS', field: 'user_ids', width: 200, wrapText: true,
			floatingFilter: true, suppressMenu: true, hide: true
		},
		{
			headerName: 'Type', field: 'type', width: 100, wrapText: true,
			suppressMenu: true, floatingFilter: true
		},
		{
			headerName: 'Status', field: 'status', width: 110, wrapText: true,
			suppressMenu: true, floatingFilter: true
		},
		{
			headerName: 'Date created', field: 'created', width: 200, wrapText: true, suppressMenu: true,
			filter: 'agDateColumnFilter', floatingFilter: true, filterParams: momentDateFilterParams, comparator: dateComparator
		},
		{
			headerName: 'Appointment date', field: 'appointment_date', width: 250, wrapText: true, suppressMenu: true,
			filter: 'agDateColumnFilter', floatingFilter: true, filterParams: momentDateFilterParams, comparator: dateComparator
		},
		{
			headerName: 'Clients', field: 'clients', width: 200, suppressMenu: true, floatingFilter: true, filterParams: { filterOptions: blanksFilterOptionsText },
			wrapText: true, cellStyle: { 'white-space': 'pre-line', 'padding-bottom': '20px' }
		},
		{
			headerName: 'Title', field: 'title', width: 150, filterParams: { filterOptions: blanksFilterOptionsText },
			suppressMenu: true, floatingFilter: true, wrapText: true,
		},
		{
			headerName: 'Services', field: 'services', width: 350, suppressMenu: true, filterParams: { filterOptions: blanksFilterOptionsText },
			floatingFilter: true, wrapText: true, cellStyle: { 'white-space': 'pre-line', 'padding-bottom': '20px' }
		},
		{
			headerName: 'Price', field: 'price_formatted', width: 130, suppressMenu: true, floatingFilter: true, filterParams: { filterOptions: blanksFilterOptionsText },
		},
		// {
		// 	headerName: 'Payments', field: 'transactions', width: 300, suppressMenu: true, floatingFilter: true, filterParams: { filterOptions: blanksFilterOptionsText },
		// 	wrapText: true, cellStyle: { 'white-space': 'pre-line', 'padding-bottom': '20px' }
		// },
    {
      headerName: 'Payments', width: 400, suppressMenu: true, filterParams: { filterOptions: blanksFilterOptionsText },
      cellRenderer: 'transactionsCell', cellRendererParams: { onClick: this.onBtnClick1.bind(this)}
    },
		{
			headerName: 'Notes', field: 'notes', width: 200, filterParams: { filterOptions: blanksFilterOptionsText },
			suppressMenu: true, floatingFilter: true, wrapText: true,
		},
		{
			headerName: 'Reminders', field: 'reminders', width: 300, suppressMenu: true, floatingFilter: true, filterParams: { filterOptions: blanksFilterOptionsText },
			wrapText: true, cellStyle: { 'white-space': 'pre-line' }
		},
		{
			headerName: 'Location', field: 'address', width: 180, filterParams: { filterOptions: blanksFilterOptionsText },
			suppressMenu: true, floatingFilter: true, wrapText: true,
		},
		{
			headerName: '', width: 90, suppressMenu: true,
			cellRenderer: 'viewInstancesButton', cellRendererParams: { onClick: this.onBtnClick2.bind(this) }
		}
	]
	gridOptions = {
		defaultColDef: defaultColDef,
		enableCellTextSelection: true,
		columnDefs: this.columnDefs,
	}
	userIdsFilter: any;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private backendSrv: BackendService
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.uid = params.get('uid')
			this.backendSrv.userData$.subscribe(userData => {
				if (userData) {
					this.userData = userData
					this.getBusinessAppointments()
				}
				else this.backToUser()
			})
		})
	}

	getBusinessAppointments() {
		this.backendSrv.getBusinessAppointments(this.userData.userData[0].bid || '').subscribe((data: IAppointment[]) => {
			const timezone = this.userData.businessData[0].timezone
			const aptTypeMap = ['Appt.', 'P.Evnt.', 'Online', 'Time off']
			const aptStatusMap = ['Accepted', 'Pending Slot', 'Pending Appt.', 'Declined', 'Cancelled']
			this.userName = this.userData.userData[0].name
			this.rowData = data
			data.forEach((apt: any, index: number) => {
				if (apt.message_ids) {
					const messsages = apt.message_ids.split(',')
					let reminders = ''
					messsages.forEach((mess: any) => {
						const [id, status] = mess.split(' . ')
						this.userData.messages.forEach((m: any) => {
							let statusMap = this.grStatusMap[parseInt(status)]
							if (m.id == id) reminders += `${m.name} - ${(m.times).split(';').join('')} (${statusMap})\n`
						});
					});
					this.rowData[index].reminders = reminders
				}
				if (apt.transactions) this.rowData[index].transactions = apt.transactions.replaceAll('currency', this.userData.businessData[0].currency)
				this.rowData[index].type = aptTypeMap[apt.type]
				this.rowData[index].timezone = timezone
				this.rowData[index].price_formatted = apt.price_formatted + ' ' + this.userData.businessData[0].currency
				this.rowData[index].status = aptStatusMap[apt.status]
				this.rowData[index].created = moment(apt.created_at).tz(timezone).format('dd MMM D YYYY, HH:mm:ss') + ' ' + timezone
				this.rowData[index].appointment_date = moment(apt.start_datetime).tz(timezone).format('dd MMM D YYYY, HH:mm:ss') + ' - ' +
					moment(apt.end_datetime).tz(timezone).format('HH:mm:ss') + ' ' + timezone
			});
		})
	}

	gridReady(event: any) {
		this.gridApi = event.api
		this.userIdsFilter = event.api.getFilterInstance('user_ids')
		this.userIdsFilter.setModel({ type: 'contains', filter: this.uid })
	}

	resetAllFilters() {
		this.gridApi.setFilterModel(null);
		this.searchValue = ''
	}

	filterCurrentUserAppointments(event: any) {
		this.currentUser = event.checked
		event.checked ? this.userIdsFilter.setModel({ type: 'contains', filter: this.uid }) : this.userIdsFilter.setModel({ type: 'contains', filter: '' })
		this.gridApi.onFilterChanged()
	}

	goToClients() { this.router.navigate(['admin', 'view', 'user', this.uid, 'clients']) }

	goToMassMessages() { this.router.navigate(['admin', 'view', 'user', this.uid, 'mass-messages']) }

	backToUser() { this.router.navigate(['admin', 'view', 'user', this.uid]) }

	countDisplayedRows(params: any) { this.visibleRows = params.api.getDisplayedRowCount() }

	onBtnExport() { this.gridApi.exportDataAsCsv() }

	private onBtnClick1(_: any) { return }
  private onBtnClick2(_: any) { return }
}
