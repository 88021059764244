import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditUserComponent } from './edit-user/edit-user.component';
import { MassMessagesComponent } from './mass-messages/mass-messages.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { UserAppointmentsComponent } from './user-appointments/user-appointments.component';
import { UserClientsComponent } from './user-clients/user-clients.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UsersComponent } from './users/users.component';
import { RevenueCatComponent } from './view-user/revenue-cat/revenue-cat.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { NavbarComponent } from './_shared/components/navbar/navbar.component';
import { AuthGuard } from './_shared/guards/auth.guard';
import { CanAdminGuard } from './_shared/guards/can-admin.guard';
import { CanEditGuard } from './_shared/guards/can-edit.guard';
import { CanReadGuard } from './_shared/guards/can-read.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./_mAuth/auth.module').then(m => m.AuthModule) },
  {
    path: 'admin', component: NavbarComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'user-profile', pathMatch: 'full' },
      { path: 'user-profile', component: UserProfileComponent, data: { animation: 'isUsers' } },
      { path: 'users', component: UsersComponent, canActivate: [CanReadGuard] },
      { path: 'view/user/:uid', component: ViewUserComponent, canActivate: [CanReadGuard] },
      { path: 'view/user/:uid/appointments', component: UserAppointmentsComponent, canActivate: [CanReadGuard] },
      { path: 'view/user/:uid/clients', component: UserClientsComponent, canActivate: [CanReadGuard] },
      { path: 'view/user/:uid/mass-messages', component: MassMessagesComponent, canActivate: [CanReadGuard] },
      { path: 'view/user/:uid/revenue-cat', component: RevenueCatComponent, canActivate: [CanReadGuard] },
      { path: 'edit/user/:uid', component: EditUserComponent, canActivate: [CanEditGuard] },
      { path: 'push-notifications', component: PushNotificationsComponent, canActivate: [CanEditGuard] },
      { path: 'roles', loadChildren: () => import('./_mRoles/roles.module').then(m => m.RolesModule), canActivate: [CanAdminGuard] },
    ]
  },
  { path: '', redirectTo: 'auth/sign-in', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
