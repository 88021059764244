import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { defaultColDef } from 'src/app/_shared/AgGridUtils';
import { BackendService } from 'src/app/_shared/services/backend.service';

@Component({
  selector: 'app-recipients-modal',
  templateUrl: './recipients-modal.component.html',
  styleUrls: ['./recipients-modal.component.sass'],
  encapsulation: ViewEncapsulation.None

})
export class RecipientsModalComponent implements OnInit {
  rowData: any = []
  private columnDefs: ColDef[] = [
    {
      headerName: 'ID', field: 'id', flex: 1, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Name', field: 'name', flex: 1, wrapText: true, floatingFilter: true,
      suppressMenu: true
    },
    {
      headerName: 'Phone Number', field: 'phone', flex: 1, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
  ]
  gridOptions = {
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    columnDefs: this.columnDefs,
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RecipientsModalComponent>,
    private backendSrv: BackendService
  ) { }

  ngOnInit(): void {
    this.backendSrv.getMassMessagesRecipients(this.data.id)
      .subscribe(recipients => this.rowData = recipients);
  }

  closeDialog() { this.dialogRef.close('') }
}
