import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BackendService } from 'src/app/_shared/services/backend.service';

@Component({
  selector: 'app-set-active-cell-button',
  templateUrl: './set-active-cell-button.component.html',
  styleUrls: ['./set-active-cell-button.component.sass']
})
export class SetActiveCellButtonComponent implements ICellRendererAngularComp {
  params: any;
  showButton: boolean = false
  uid: string | null = '';

  constructor(
    private backendSrv: BackendService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(params => {
      this.uid = params.get('uid')
    })
  }

  agInit(params: any): void {
    this.params = params;
    this.showButton = this.params.node.data.active !== "yes"
  }

  refresh(params?: any): boolean { return true }

  setActive($event: any) {
    if (this.params.onClick instanceof Function) {
      const data = {
        uid: this.uid || '',
        sid: this.params.node.data.id
      }
      this.backendSrv.setSubscription(data).subscribe(_ => location.reload())
    }
  }
}
