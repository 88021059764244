import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralSnackBarComponent } from '../components/general-snack-bar/general-snack-bar.component';

export interface ISnackbarData {
  title: string,
  description: string
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarMessagingService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  openSnackBarMessage(snackbarData: ISnackbarData, panelClass: string = 'info', durationInSeconds: number = 2) {
    this.snackBar.openFromComponent(GeneralSnackBarComponent, {
      data: {
        title: snackbarData.title,
        description: snackbarData.description,
      },
      panelClass: panelClass,
      duration: durationInSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}