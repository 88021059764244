<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title fxLayoutAlign="start center">
      <span>Settings</span>
    </mat-panel-title>
    <mat-panel-description>
      <span fxFlex='30'> <span class="bolderFont">Timezone: </span>
        {{businessData.timezone}}</span>
      <span fxFlex='30'> <span class="bolderFont">Currency: </span>
        {{businessData.currency}}
        <span class="bolderFont">and language:</span>
        {{businessData.language}}</span>
      <mat-icon>settings</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div fxLayout="column">
    <mat-divider class="matDividerAppointfixBlue"></mat-divider>
    <h2 class="settingsSubheader" fxFlexFill fxLayoutAlign="center center">Message
      Templates</h2>
    <div fxFlexOffset="1"></div>
    <div *ngFor="let message of messages" fxLayout="column">
      <div fxFlexOffset="1"></div>
      <span fxFlex="100">
        <span fxFlex="34" class="bolderFont">Message name </span>
        {{message.name}}
      </span>
      <span fxFlex="100">
        <span fxFlex="34" class="bolderFont">Message times </span>
        {{message.times}}
      </span>
      <span fxFlex="100">
        <span fxFlex="34" class="bolderFont">Message template </span>
        {{message.template}}
      </span>
      <span fxFlex="100"> <span fxFlex="34" class="bolderFont">Message date/time format </span>
        <div fxLayout="column">
          <span *ngFor="let m of message.date_time_format">
            {{m[0]}}: {{m[1]}}
          </span>
        </div>
      </span>
      <span fxFlex="100">
        <span fxFlex="34" class="bolderFont">Default message </span>
        {{message.is_default.data[0] == 1 ? 'Yes':'No'}}
      </span>
      <div fxFlexOffset="2"></div>
    </div>
  </div>
</mat-expansion-panel>