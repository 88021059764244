import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { dateFilterParams, defaultColDef } from '../_shared/AgGridUtils';
import { IAppUserMassMessage } from '../_shared/interfaces/app-user-mass-message';
import { BackendService } from '../_shared/services/backend.service';
import { ViewRecipientsCellButtonComponent } from './view-recipients-cell-button/view-recipients-cell-button.component';

@Component({
  selector: 'app-mass-messages',
  templateUrl: './mass-messages.component.html',
  styleUrls: ['./mass-messages.component.sass']
})
export class MassMessagesComponent implements OnInit {
  uid: string | null = '';
  userName: string | null = '';
  userData: any;
  searchValue: string = '';
  visibleRows: any = false
  rowData: IAppUserMassMessage[] = [];
  frameworkComponents = { viewRecipientsButton: ViewRecipientsCellButtonComponent }
  columnDefs: ColDef[] = [
    {
      headerName: 'ID', field: 'id', flex: 1.5, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Message content', field: 'message_text', flex: 2.5, wrapText: true,
      suppressMenu: true, floatingFilter: true
    },
    {
      headerName: 'Sent via', field: 'send_through_device', flex: 1, wrapText: true,
      floatingFilter: true, suppressMenu: true
    },
    {
      headerName: 'Created at', field: 'created_at', flex: 1.5, wrapText: true, suppressMenu: true,
      filter: 'agDateColumnFilter', floatingFilter: true, filterParams: dateFilterParams
    },
    {
      headerName: 'Num. recipients', width: 135, suppressMenu: true, cellRenderer: 'viewRecipientsButton',
      cellRendererParams: { onClick: this.onBtnClick1.bind(this) }, sortable: false,
    }
  ]
  gridOptions = {
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    columnDefs: this.columnDefs,
  }
  private gridApi: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private backendSrv: BackendService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.uid = params.get('uid')
      this.backendSrv.userData$.subscribe(userData => {
        if (userData) {
          this.userData = userData
          this.getUserMassMessages()
        }
        else this.backToUser()
      })
    })
  }

  getUserMassMessages() {
    this.backendSrv.getUserMassMessages(this.uid || '').subscribe((data: IAppUserMassMessage[]) => {
      this.userName = this.userData.userData[0].name
      this.rowData = data
      data.forEach((mm: any, index: number) => {
        this.rowData[index].created_at = mm.updated_at.split('T').join(' ').split('.')[0] + ' ' + this.userData.userSettings[0].timezone
        if (mm.send_through_device) this.rowData[index].send_through_device = mm.send_through_device.data.lastIndexOf(1) == 0 ? 'Device' : 'Server'
      });
    })
  }

  resetAllFilters() {
    this.gridApi.setFilterModel(null);
    this.searchValue = ''
  }

  onGridReady(params: any) { this.gridApi = params.api }

  countDisplayedRows(params: any) { this.visibleRows = params.api.getDisplayedRowCount() }

  onBtnExport() { this.gridApi.exportDataAsCsv() }

  backToUser() { this.router.navigate(['admin', 'view', 'user', this.uid]) }

  goToAppointments() { this.router.navigate(['admin', 'view', 'user', this.uid, 'appointments']) }

  goToClients() { this.router.navigate(['admin', 'view', 'user', this.uid, 'clients']) }

  private onBtnClick1(e: any) { return }
}