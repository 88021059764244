<mat-selection-list color="primary">
  <mat-form-field>
    <input type="text" placeholder="search" matInput #input [(ngModel)]="search">
  </mat-form-field>
  <mat-list-option [checkboxPosition]="'before'" [selected]="isEverythingSelected" (click)="toggleEverything()">
    Select All
  </mat-list-option>
  <div *ngFor="let item of onFilterValuesChanged$ | async">
    <mat-list-option *ngIf="item.toLowerCase().indexOf(search) != -1" (click)="toggleItem(item)"
      [checkboxPosition]="'before'" [value]="item" [selected]="selectedValuesMap[item]">
      {{item}}
    </mat-list-option>
  </div>
</mat-selection-list>