import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RecipientsModalComponent } from '../recipients-modal/recipients-modal.component';

@Component({
  selector: 'app-view-recipients-cell-button',
  templateUrl: './view-recipients-cell-button.component.html',
  styleUrls: ['./view-recipients-cell-button.component.sass']
})
export class ViewRecipientsCellButtonComponent implements ICellRendererAngularComp {
  params: any;

  constructor(
    private dialog: MatDialog,
    readonly ngZone: NgZone
  ) { }

  agInit(params: any): void { this.params = params }

  refresh(params?: any): boolean { return true; }

  viewRecipients() {
    if (this.params.onClick instanceof Function) this.openInfoModal(this.params.node.data)
  }

  private openInfoModal(data: any) {
    this.ngZone.run(() => {
      this.dialog.open(RecipientsModalComponent, {
        data: data,
        maxWidth: '100vw',
        maxHeight: '82vh',
        height: '100%',
        width: '87%',
        panelClass: 'recipientsModal'
      })
    })
  }

}
