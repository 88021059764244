import { Component, Input, OnInit } from '@angular/core';
import { processDateWithBucharestTZ } from 'src/app/_shared/AgGridUtils';

@Component({
  selector: 'user-details-panel',
  templateUrl: './user-details-panel.component.html',
  styleUrls: ['./user-details-panel.component.sass']
})
export class UserDetailsPanelComponent implements OnInit {
  @Input() userData: any = {};
  @Input() userSettings: any = {};
  @Input() timezone: string = ''
  planCss: string = ''
  private userStateMap = ['Active', 'Invited', 'Blocked', 'Inactive', 'Deleted']

  ngOnInit() {
    this.planCss = this.planClass()
    this.processUserInfo()
  }

  private processUserInfo() {
    if (this.userData.profession_name) this.userData.profession_name = JSON.parse(this.userData.profession_name.split('"\""').join())
    this.userData.state = this.userStateMap[parseInt(this.userData.state)]
    this.userData.signInMethod = this.signInMetodParser()
    this.userData.purchase_date = processDateWithBucharestTZ(this.userData.purchase_date, this.timezone);
    this.userData.expiry_date = processDateWithBucharestTZ(this.userData.expiry_date, this.timezone);
    this.userData.end_date = processDateWithBucharestTZ(this.userData.end_date, this.timezone);
  }

  private signInMetodParser() {
    let signIn = []
    if (!!this.userData.login_email) signIn.push({ "type": "Email", "date": this.userData.login_email })
    if (!!this.userData.login_apple) signIn.push({ "type": "Apple", "date": this.userData.login_apple })
    if (!!this.userData.login_facebook) signIn.push({ "type": "Facebook", "date": this.userData.login_facebook })
    if (!!this.userData.login_google) signIn.push({ "type": "Google", "date": this.userData.login_google })
    let sorted = signIn.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    return sorted.map(l => l.type).join(', ')
  }

  planClass() {
    let plan = this.userData.p_name.toLowerCase()
    if (plan.indexOf('premium') > -1) return 'premiumPlan'
    else if (plan.indexOf('ultimate') > -1) return 'ultimatePlan'
    else return 'basicPlan'
  }
}
