import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import { BackendService } from '../../_shared/services/backend.service';
import {take} from "rxjs/operators";
import {AuthService} from "../../_shared/services/auth.service";
import {IUser} from "../../_shared/interfaces/mobi-user";

@Component({
  selector: 'app-transactions-cell',
  templateUrl: './transactions-cell.component.html',
  styleUrls: ['./transactions-cell.component.sass']
})
export class TransactionsCellComponent implements ICellRendererAngularComp {
  showButton: boolean = false
  transactions:string =''
  private params: any;
  private user!: IUser;

  constructor(
    private auth: AuthService,
    private backendSrv: BackendService
  ) {
    this.auth.user$.subscribe(user => {
      this.user = user
    })
  }

  agInit(params: any): void {
    this.params = params;
    this.transactions = this.params.node.data.transactions
    this.showButton = !!this.transactions && this.auth.canAdmin(this.user)
  }

  refresh(params?: any): boolean { return true; }

  refundAppointment(){
    if (this.params.onClick instanceof Function) {
      if(confirm("Are you sure you want to refund?")){
        this.backendSrv.refundAppointment(this.params.node.data.id).pipe(take(1)).subscribe()
      }
    }
  }
}
