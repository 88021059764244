import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { BackendService } from '../_shared/services/backend.service';
import { AuthService } from '../_shared/services/auth.service';
import { SetActiveCellButtonComponent } from './grid-cells/set-active-cell-button/set-active-cell-button.component';
import { OrderCellButtonComponent } from './grid-cells/order-cell-button/order-cell-button.component';
import { DeviceLogCellButtonComponent } from './grid-cells/device-log-cell-button/device-log-cell-button.component';
import { dateFilterParams, defaultColDef, makeWorkingHoursArray } from '../_shared/AgGridUtils';
import { RowCellButtonsComponent } from '../users/row-cell-buttons/row-cell-buttons.component';

@Component({
	selector: 'app-view-user',
	templateUrl: './view-user.component.html',
	styleUrls: ['./view-user.component.sass'],
	encapsulation: ViewEncapsulation.None
})
export class ViewUserComponent implements OnInit {
	@ViewChild(MatAccordion) accordion!: MatAccordion;

	uid: string | null = ''
	userInfo: any
	user: any;
	searchValue: string = ''
	staffRowData: any[] = []
	devicesRowData: any[] = []
	servicesRowData: any[] = []
	subscriptionsRowData: any[] = []
	// referralsRowData: any[] = []
	purchasesRowData: any[] = []
	visibleRows: any = {}
	frameworkComponents = {
		setActiveButton: SetActiveCellButtonComponent,
		orderButton: OrderCellButtonComponent,
		logButtons: DeviceLogCellButtonComponent,
		userActionButtons: RowCellButtonsComponent
	}
	rowClassCurrentUser = {
		"currentUserRow": (params: any) => params.data.user_id ? params.data.user_id.indexOf(this.uid) > -1 : '',
	}
	defaultGridOptions = {
		defaultColDef: defaultColDef,
		enableCellTextSelection: true
	}
	staffColDef: ColDef[] = [
		{
			headerName: '', cellRenderer: 'userActionButtons', width: 90, pinned: true,
			cellRendererParams: { onClick: this.onBtnClick.bind(this) }
		},
		{ headerName: 'Name', field: 'name', flex: 1.2, wrapText: true },
		{ headerName: 'Profession', field: 'profession_name', flex: 1.2, wrapText: true },
		{ headerName: 'Phone', field: 'phone_number', flex: 1, wrapText: true },
		{ headerName: 'Email', field: 'email', flex: 2, wrapText: true },
		{ headerName: 'Role', field: 'role', flex: 1.2, wrapText: true },
		{ headerName: 'Working Hours', field: 'workHours', flex: 1.7, wrapText: true, cellStyle: { 'white-space': 'pre-line' } },
		{ headerName: 'Online B.', field: 'online_booking', flex: 1, wrapText: true },
		{ headerName: 'State', field: 'state', flex: 0.8, wrapText: true },
	]
	devicesColDef: ColDef[] = [
		{ headerName: 'Device name', field: 'name', flex: 1.3, wrapText: true },
		{ headerName: 'User name', field: 'user_name', flex: 1.3, wrapText: true },
		{ headerName: 'Os', field: 'type', flex: 0.7 },
		{ headerName: 'Os version', field: 'os_version', flex: 0.8 },
		{ headerName: 'App version', field: 'app_version', flex: 0.9 },
		{ headerName: 'Device id', field: 'd_s_device_id', flex: 0.8 },
		{ headerName: 'Token', field: 'd_s_push_token', flex: 1.5 },
		{ headerName: 'Sync', field: 'd_s_local_sync', flex: 0.6 },
		{ headerName: 'Last login', field: 'last_login', filter: 'agDateColumnFilter', filterParams: dateFilterParams, flex: 1 },
		{ headerName: 'Sending device', field: 'sending_device', flex: 1 },
		{
			headerName: 'Action', cellRenderer: 'logButtons', width: 95, wrapText: true,
			cellRendererParams: { onClick: this.onBtnClick.bind(this) }
		},
	]
	servicesColDef: ColDef[] = [
		{ headerName: 'ID', field: 'id', flex: 1.5, wrapText: true },
		{ headerName: 'Name', field: 'name', flex: 1.5, wrapText: true },
		{ headerName: 'Duration', field: 'duration', flex: 0.8 },
		{ headerName: 'Processing time', field: 'processing_time', flex: 1.1 },
		{ headerName: 'Extra time', field: 'extra_time', flex: 0.9 },
		{ headerName: 'Price', field: 'price', flex: 0.8 },
    { headerName: 'Deposit', field: 'deposit_amount', flex: 0.8 },
    { headerName: 'Default', field: 'is_default', flex: 0.8 },
		{ headerName: 'Bookable online', field: 'online_booking', flex: 1 },
	]
	subscriptionsColDef: ColDef[] = [
		{ headerName: 'Product', field: 'plan_name', flex: 2, wrapText: true },
		{ headerName: 'Platform', field: 'platform', flex: 1 },
		{ headerName: 'Purchase date', field: 'purchase_date', filter: 'agDateColumnFilter', filterParams: dateFilterParams, flex: 2 },
		{ headerName: 'Expiry date', field: 'expiry_date', filter: 'agDateColumnFilter', filterParams: dateFilterParams, flex: 2 },
		{ headerName: 'Active', field: 'active', flex: 1, wrapText: true },
		{
			headerName: 'Action', cellRenderer: 'setActiveButton', flex: 1,
			cellRendererParams: { onClick: this.onBtnClick.bind(this) }
		},
		{ headerName: 'subscription_id', field: 'subscription_id', flex: 2, hide: true }
	]
	purchasesColDef: ColDef[] = [
		{ headerName: 'Product', field: 'plan_name', flex: 2, wrapText: true },
		{ headerName: 'Price', field: 'price', flex: 1 },
		{ headerName: 'Platform', field: 'platform', flex: 1 },
		{
			headerName: 'Order ID', cellRenderer: 'orderButton', flex: 1,
			cellRendererParams: { onClick: this.onBtnClick.bind(this) }
		},
		{
			headerName: 'Purchase date', field: 'purchase_date', flex: 2,
			filter: 'agDateColumnFilter', filterParams: dateFilterParams
		},
		{
			headerName: 'Expiry date', field: 'expiry_date', flex: 2,
			filter: 'agDateColumnFilter', filterParams: dateFilterParams
		},
	]

	private userStateMap = ['Active', 'Invited', 'Blocked', 'Inactive', 'Deleted']

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private backendSrv: BackendService,
		public auth: AuthService
	) { }

	ngOnInit(): void {
		this.auth.user$.subscribe(user => this.user = user)
		this.route.paramMap.subscribe(params => {
			this.uid = params.get('uid')
			if (this.uid) {
				this.backendSrv.getUserInfo(this.uid).subscribe((info: any) => {
					const platformMapping = ['IOS', 'ANDROID', 'WEB', 'UNKNOWN', 'ADMIN', 'SYSTEM']
					this.userInfo = info
					this.userInfo.devices.forEach((d: any, index: number) => {
						this.userInfo.devices[index].sending_device = d.id === info.businessData[0].sending_device ? 'yes' : 'no'
						d.d_s_local_sync ? this.userInfo.devices[index].d_s_local_sync = d.d_s_local_sync.data[0] == 1 ? 'yes' : 'no' : this.userInfo.devices[index].d_s_local_sync = 'no'
					});
					this.userInfo.staffData.forEach((staff: any) => {
						staff.profession_name = staff.profession_id ? staff.profession_id : staff.profession_custom
						staff.online_booking = staff.online_booking == 1 ? 'yes' : 'no'
						staff.state = this.userStateMap[parseInt(staff.state)]
						const workingHoursArr = makeWorkingHoursArray(staff)
						let workingHoursString = ''
						workingHoursArr.forEach((day: any) => {
							let schedule = ''
							day.intervals.forEach((i: any) => schedule += `${i.start} - ${i.end}; `)
							workingHoursString += `${day.name}: ${day.enabled ? schedule : 'Not working;'} \n`
						})
						staff.workHours = workingHoursString;
					})
					let activeStaffNo = this.userInfo.staffData.filter((staff: any) => staff.state === "Active").length
					this.userInfo.services.forEach((s: any, index: number) => {
            this.userInfo.services[index].price = s.price + ' ' + this.userInfo.businessData[0].currency
            this.userInfo.services[index].deposit_amount = s.deposit_amount? s.deposit_amount + ' ' + this.userInfo.businessData[0].currency : 'n/a'
						this.userInfo.services[index].staffs = s.staffs.split('\n').length === activeStaffNo ? "All staff" + '\n' : s.staffs + '\n'
						this.userInfo.services[index].is_default = s.is_default.data[0] == 1 ? 'yes' : 'no'
						this.userInfo.services[index].online_booking = s.online_booking.data[0] == 1 ? 'yes' : 'no'
					})
					this.userInfo.subscriptions.forEach((s: any, index: number) => {
						s.active_sub ? this.userInfo.subscriptions[index].active_sub = s.active_sub.data[0] == 1 ? 'yes' : 'no' : this.userInfo.subscriptions[index].active_sub = 'no'
						this.userInfo.subscriptions[index].price = (s.price / 100) + ' ' + s.currency
						this.userInfo.subscriptions[index].platform = platformMapping[s.platform]
						s.plan_name ? this.userInfo.subscriptions[index].plan_name = s.plan_name : this.userInfo.subscriptions[index].plan_name = s.sms_name
					});
					this.userInfo.purchases.forEach((p: any, index: number) => {
						this.userInfo.purchases[index].active = p.active.data[0] == 1 ? 'yes' : 'no'
						this.userInfo.purchases[index].platform = platformMapping[p.platform]
					});
					this.staffRowData = this.userInfo.staffData
					this.servicesRowData = this.userInfo.services
					this.subscriptionsRowData = this.userInfo.purchases
					this.devicesRowData = this.userInfo.devices
					this.purchasesRowData = this.userInfo.subscriptions
				})
			}
		})
	}

	sortGrid(event: any, field: any, sortDir: any) {
		const columnState = { state: [{ colId: field, sort: sortDir }] }
		event.columnApi.applyColumnState(columnState);
	}

	navigateToAppointments() { this.router.navigate(['admin', 'view', 'user', this.uid, 'appointments']) }

	navigateToClients() { this.router.navigate(['admin', 'view', 'user', this.uid, 'clients']) }

	navigateToMassMessages() { this.router.navigate(['admin', 'view', 'user', this.uid, 'mass-messages']) }

	navigateToEditUser() { this.router.navigate(['admin', 'edit', 'user', this.uid]) }

	navigateToRevenueCat() { this.router.navigate(['admin', 'view', 'user', this.uid, 'revenue-cat']) }

	countDisplayedRows(params: any, grid: string) { this.visibleRows[grid] = params.api.getDisplayedRowCount(); }

	private onBtnClick(_: any) { return }
}
