<div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <div fxFlexOffset="6" class="fullWidth">
    <div fxFlexFill fxLayoutAlign="start center">
      <div fxFlex>
        <button mat-raised-button class="ationBarButton mat-elevation-z10" color="secondary" (click)="backToUser()">
          View User
        </button>
      </div>
      <button mat-raised-button [disabled]="!editUserForm.valid || editUserForm.pristine || sameValues"
        class="formButton mat-elevation-z10" color="primary" (click)="submitChanges()">
        Submit Changes
      </button>
      <button mat-raised-button [disabled]="editUserForm.pristine || sameValues" fxFlexOffset="1"
        class="formButton mat-elevation-z10" color="warn" (click)="discardChanges()">
        Discard Changes
      </button>
      <div fxFlex fxLayoutAlign="end center">
        <div *ngIf="auth.canEdit(user)">
          <button *ngIf="isLiveEnv" mat-raised-button class="ationBarButton mat-elevation-z10" color="primary"
            (click)="createReplica()">
            Create Replica
          </button>
          <button *ngIf="!isDeleted" mat-raised-button class="ationBarButton mat-elevation-z10" fxFlexOffset="2"
            color="warn" (click)="deleteBusiness()">
            Delete
          </button>
          <button *ngIf="isDeleted" mat-raised-button class="ationBarButton mat-elevation-z10" fxFlexOffset="2"
            color="primary" (click)="reviveBusiness()">
            Revive
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="matDividerAppointfixBlue topDivider"></mat-divider>
  </div>
  <div fxFlex="auto" class="fullWidth" style="overflow: auto;" fxFlexOffset="2">
    <div fxLayout="column">
      <div fxFlexOffset="2"></div>
      <h2 fxLayoutAlign="center center">Edit User {{userName}}</h2>
      <form [formGroup]="editUserForm" fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="center space-between" fxFlexOffset="2">
          <mat-form-field fxFlex appearance="fill">
            <mat-label>{{signInMethod}}</mat-label>
            <input matInput type="email" #email name="email" formControlName="email" maxlength="191">
          </mat-form-field>

          <mat-form-field fxFlex fxFlexOffset="3" appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput type="text" name="userPassword" formControlName="userPassword" maxlength="191">
          </mat-form-field>

          <mat-form-field fxFlex appearance="fill" fxFlexOffset="3">
            <mat-label>Admin User Note</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="9" matInput name="userNotes"
              formControlName="userNotes"></textarea>
          </mat-form-field>
        </div>
        <mat-divider class="matDividerAppointfixBlue " fxFlexOffset="2"></mat-divider>

        <div *ngIf="userRoleOwner" fxLayout="row" fxLayoutAlign="center space-between" fxFlexOffset="4">
          <mat-form-field fxFlex appearance="fill">
            <mat-label>Plan</mat-label>
            <mat-select formControlName="plan" (selectionChange)="planChanged()">
              <mat-option *ngFor="let plan of plans" [value]="plan.id">
                {{plan.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="12" fxFlexOffset="3" appearance="fill">
            <mat-label>Price</mat-label>
            <input matInput type="number" name="price" formControlName="price">
          </mat-form-field>

          <mat-form-field fxFlex="12" appearance="fill">
            <mat-label>Currency</mat-label>
            <mat-select formControlName="currency">
              <mat-option *ngFor="let c of currencyList" [value]="c.currency">
                {{c.currency}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex fxFlexOffset="3" appearance="fill">
            <mat-label>Expiry Date (mm/dd/yyyy)</mat-label>
            <input matInput #expiryDate [matDatepicker]="picker" (focus)="picker.open()" formControlName="expiryDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
          </mat-form-field>

        </div>
        <div *ngIf="userRoleOwner" fxLayout="row" fxLayoutAlign="start space-between" fxFlexOffset="3">

          <mat-form-field fxFlex="35" appearance="fill">
            <mat-label>{{monthlyReminders}}</mat-label>
            <mat-select formControlName="pack">
              <mat-option *ngFor="let pack of smsPack" [value]="pack.id">
                {{pack.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="initialValues.transactionLimit" fxFlex="16" fxFlexOffset="auto" appearance="fill">
            <mat-label>Transaction Limit</mat-label>
            <input matInput type="number" name="transactionLimit" formControlName="transactionLimit">
          </mat-form-field>

          <mat-form-field *ngIf="initialValues.payoutLimit" fxFlex="16" fxFlexOffset="3" appearance="fill">
            <mat-label>Payout limit</mat-label>
            <input matInput type="number" name="payoutLimit" formControlName="payoutLimit">
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
