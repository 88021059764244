<h1 mat-dialog-title>Hi</h1>
<div mat-dialog-content>
  <p>Please enter a name for present filter configuration.</p>
  <mat-form-field appearance="fill" style="padding: 0 !important; width: 100% !important;">
    <mat-label>Configuration Name</mat-label>
    <input matInput [(ngModel)]="filterName" cdkFocusInitial>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button style="border-radius: 0;" (click)="onNoClick()">No Thanks</button>
  <button mat-raised-button color="primary" style="border-radius: 0;" [mat-dialog-close]="filterName">Ok</button>
</div>