import { Component, Input, OnInit } from '@angular/core';
// import { makeWorkingHoursArray } from 'src/app/_shared/AgGridUtils';

@Component({
  selector: 'user-settings-panel',
  templateUrl: './user-settings-panel.component.html',
  styleUrls: ['./user-settings-panel.component.sass']
})
export class UserSettingsPanelComponent implements OnInit {
  @Input() userSettings: any = {};
  @Input() businessData: any = {};
  @Input() messages: any = {};

  ngOnInit(): void {
    // this.userSettings.working_time = makeWorkingHoursArray(this.userSettings)
    this.messages.forEach((m: any, index: number) => {
      this.messages[index].times = calcMessageTimes(m.times);
      let dtFormat = JSON.parse(m.date_time_format.split('"\""').join());
      let newFormat = Object.entries(dtFormat).map(([key, value]) => {
        let newKey = key.split("_").join(" ")
        newKey = capitalizeFirstLetter(newKey)
        return [newKey, value]
      })
      this.messages[index].date_time_format = newFormat;
    });
  }
}

function capitalizeFirstLetter(s: string) {
  let words = s.split(" ");
  words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
  return words.join(" ");
}

function calcMessageTimes(times: string) {
  let resString = '';
  let timesArr = JSON.parse(times);
  timesArr.forEach((time: number) => {
    if (time == 0) resString = 'Now '
    else if (time == -1) resString = 'Instantly '
    else {
      if (time > 0) {
        if (time < 3600) resString += Math.round(time / 60) + (time / 60 == 1 ? ' minute' : ' minutes')
        if (time >= 3600 && time < 86400) resString += Math.round(time / 3600) + (time / 3600 == 1 ? ' hour' : ' hours')
        if (time >= 86400 && time < 604800) resString += Math.round(time / 86400) + (time / 86400 == 1 ? ' day' : ' days')
        if (time >= 604800) resString += Math.round(time / 604800) + (time / 604800 == 1 ? ' week' : ' weeks')
        resString += ' after '
      } else {
        time = time * -1
        if (time < 3600) resString += Math.round(time / 60) + (time / 60 == 1 ? ' minute' : ' minutes')
        if (time >= 3600 && time < 86400) resString += Math.round(time / 3600) + (time / 3600 == 1 ? ' hour' : ' hours')
        if (time >= 86400 && time < 604800) resString += Math.round(time / 86400) + (time / 86400 == 1 ? ' day' : ' days')
        if (time >= 604800) resString += Math.round(time / 604800) + (time / 604800 == 1 ? ' week' : ' weeks')
        resString += ' before '
      }
    }
  });
  return resString
}