import { Component, ViewEncapsulation } from "@angular/core";
import { IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: "td-set-filter",
  templateUrl: "./select-filter.component.html",
  styleUrls: ["./select-filter.component.sass"],
  encapsulation: ViewEncapsulation.None
})
export class SelectFilterComponent implements IFilterAngularComp {

  // private model: Array<string> | undefined;
  private uniqueCheck: any = {};
  private filterParams: any;
  private fieldId: any = '';
  private filterList: Array<string> = [];
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  search: string = '';
  isEverythingSelected: boolean | undefined;

  containerHeight: number = 0;

  selectedValuesMap: any = {};
  filteredResults: any = []

  onFilterListChange$: BehaviorSubject<string[]> = new BehaviorSubject(['']);
  onFilterValuesChanged$: Observable<string[]> | undefined;


  agInit(params: IFilterParams): void {
    this.filterParams = params;
    this.uniqueCheck = {};
    this.selectedValuesMap = {};
    this.filterList = new Array<string>();

    this.fieldId = params.colDef.field;

    this.onFilterListChange$ = new BehaviorSubject<string[]>([]);
    this.ngUnsubscribe = new Subject<any>();

    this.onFilterValuesChanged$ = this.onFilterListChange$
      .pipe(
        debounceTime(250),
        tap(values => this.sortValues(values)),
        // tap(values => this.setContainerHeight())
      );

    // this.setUniqueValues();
    // this.selectEverything();
  }

  setUniqueValues() {
    this.filterParams.rowModel.forEachNode((node: RowNode) => {
      if (!node.data) {
        return;
      }

      let value = node.data[this.fieldId];
      this.addUniqueValueIfMissing(value);
    });
  }

  toggleItem(value: string) {
    if (this.selectedValuesMap[value]) {
      delete this.selectedValuesMap[value];
    } else {
      this.selectedValuesMap[value] = 1;
    }

    this.isEverythingSelected =
      Object.keys(this.selectedValuesMap).length === this.filterList.length;

    this.onFilterChanged();
  }

  toggleEverything() {
    this.isEverythingSelected = !this.isEverythingSelected;
    if (this.isEverythingSelected) {
      this.selectEverything();
    } else {
      this.unselectEverything();
    }

  }

  onNewRowsLoaded() {
    this.setUniqueValues();
  }

  isFilterActive(): boolean {
    return Object.keys(this.selectedValuesMap).length > 0 && !this.isEverythingSelected;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const node = params.node;
    if (!node.data) {
      return false;
    }

    const nodeValue = params.data[this.fieldId];
    if (!this.isValueValid(nodeValue)) {
      return false;
    }
    // check if current row node value exists in our selectedValuesMap
    return Boolean(this.selectedValuesMap[nodeValue]);
  }

  getModel() {
    return !Object.keys(this.selectedValuesMap).length ? null : this.selectedValuesMap
  }

  onFilterChanged() {
    this.filterParams.filterChangedCallback();
  }

  setModel(value: string) {
    this.unselectEverything();
    this.selectedValuesMap[value] = 1;
    this.addUniqueValueIfMissing(value);
  }

  addValues(values: string[]) {
    this.unselectEverything();
    if (values.length) {
      values.forEach(value => this.toggleItem(value))
    }
  }

  destroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private sortValues(values: string[]) {
    values.sort();
  }

  public selectEverything() {
    this.filterList.forEach(value => (this.selectedValuesMap[value] = 1));
    this.isEverythingSelected = true;
    this.onFilterChanged();
  }

  public unselectEverything() {
    this.selectedValuesMap = {};
    this.isEverythingSelected = false;
    this.onFilterChanged();
  }

  private addUniqueValueIfMissing(value: string) {
    if (!this.isValueValid(value)) {
      return;
    }

    if (this.uniqueCheck[value]) {
      return;
    }

    this.filterList.push(value);
    this.onFilterListChange$.next([...this.filterList]);
    this.uniqueCheck[value] = 1;
  }

  private isValueValid(value: string) {
    if (value === "" || value === undefined || value === null) {
      return false;
    }

    return true;
  }
}
