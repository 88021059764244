import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { ColDef, ColumnMovedEvent, GridOptions } from 'ag-grid-community';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { blanksFilterOptionsText, dateFilterParams, defaultColDef } from '../_shared/AgGridUtils';
import { IAppUsers } from '../_shared/interfaces/app-users';
import { createOnlineObservable, debugObs } from '../_shared/RxjsUtils';
import { BackendService } from '../_shared/services/backend.service';
import { SnackbarMessagingService } from '../_shared/services/snackbar-messaging.service';
import { FilterNamePromptComponent } from './filter-name-prompt/filter-name-prompt.component';
import { FiltersStoreService } from './filters-store.service';
import { RowCellButtonsComponent } from './row-cell-buttons/row-cell-buttons.component';
import { SelectFilterComponent } from './select-filter/select-filter.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersComponent implements OnDestroy {

  readonly storageColDef = localStorage.getItem('defaultColDef') ? JSON.parse(localStorage.getItem('defaultColDef') || 'undefined') : null
  online$ = createOnlineObservable()
  searchValue = ''
  searchValueChanged = new Subject<string>();
  debouncedSearchValue = ''
  visibleRows: any = false
  rowData: IAppUsers[] = []
  updatedAt$ = this.backendSrv.userDataUpdatedAt$
  subNo: number | undefined
  nDays: number | undefined
  autoRefresh: boolean = localStorage.getItem('autoRefresh') === "true"
  gridApi: any
  columnGridApi: any
  filterModel: any = []
  storeFilters: any = []
  activeFiltersModel: any[] = []
  loadingTemplate = `<span class="ag-overlay-loading-center">Data is loading...</span>`
  customFilters = ['gdpr', 'user_role', 'state_name', 'u_s_country', 'profession_id', 'p_name']
  frameworkComponents = {
    selectFilter: SelectFilterComponent,
    buttonRenderer: RowCellButtonsComponent
  }
  columnDefs: ColDef[] = this.storageColDef ? this.storageColDef : [
    {
      headerName: '', field: 'actions', cellRenderer: 'buttonRenderer', width: 85, wrapText: true, pinned: true,
      lockPosition: true, filter: false, cellRendererParams: { onClick: this.onBtnClick.bind(this) }
    },
    {
      headerName: 'ID', field: 'id', width: 150, filter: true,
      floatingFilter: true, suppressMenu: true, hide: false
    },
    {
      headerName: 'Business ID', field: 'bid', width: 150, filter: true,
      floatingFilter: true, suppressMenu: true, hide: false
    },
    {
      headerName: 'Name', field: 'name', width: 200, filter: true, wrapText: true, filterParams: { filterOptions: blanksFilterOptionsText },
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Business Name', field: 'business_name', width: 200, filter: true, wrapText: true, filterParams: { filterOptions: blanksFilterOptionsText },
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Email', field: 'email', width: 200, filter: true, filterParams: { filterOptions: blanksFilterOptionsText }, wrapText: true,
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Phone', field: 'phone_number', width: 200, filter: true, wrapText: true, filterParams: { filterOptions: blanksFilterOptionsText },
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Plan', field: 'p_name', width: 100, filter: 'selectFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Country', field: 'u_s_country', width: 100, filter: 'selectFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'OS', field: 'device_os_type', width: 100, filter: true, filterParams: { filterOptions: blanksFilterOptionsText },
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Created (UTC)', field: 'created_at', width: 130, filter: 'agDateColumnFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, filterParams: dateFilterParams, hide: false
    },
    {
      headerName: 'Updated (UTC)', field: 'updated_at', width: 130, filter: 'agDateColumnFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, filterParams: dateFilterParams, hide: false
    },
    {
      headerName: 'Role', field: 'user_role', width: 150, filter: 'selectFilter', wrapText: true,
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Profession', field: 'profession_id', width: 150, filter: 'selectFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Custom Prof.', field: 'profession_custom', width: 150, filter: true, wrapText: true,
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'Purchased (UTC)', field: 'purchase_date', width: 130, filter: 'agDateColumnFilter',
      filterParams: dateFilterParams, suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'State', field: 'state_name', width: 100, filter: 'selectFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    },
    {
      headerName: 'GDPR', field: 'gdpr', width: 100, filter: 'selectFilter',
      suppressMenu: true, floatingFilter: true, sortable: true, hide: false
    }
  ]
  gridOptions: GridOptions = {
    rowBuffer: 1,
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    columnDefs: this.columnDefs,
    suppressHorizontalScroll: false,
    onColumnMoved: (params: ColumnMovedEvent) => { if (params.toIndex === 0) params.columnApi.moveColumnByIndex(params.toIndex, 1) }
  }
  private countryFilter: any
  private planFilter: any
  private professionFilter: any
  private stateFilter: any
  private gdprFilter: any
  private roleFilter: any
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public dialog: MatDialog,
    private msgSrv: SnackbarMessagingService,
    private backendSrv: BackendService,
    private router: Router,
    private filterStoreSrv: FiltersStoreService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      take(1),
      // debugObs('Route start')
    ).subscribe(_ => {
      const userFilterModel = this.gridApi.getFilterModel();
      filterStoreSrv.updateFilters('x Filter Before Page Changed x', userFilterModel)
    });
    this.filterStoreSrv.userFilters$.subscribe(filters => this.storeFilters = filters)
    this.searchValueChanged.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      // debugObs('Search')
    ).subscribe(searchValue => this.debouncedSearchValue = searchValue)
    this.backendSrv.environment$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(_ => {
      if (this.autoRefresh) this.refreshData()
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  refreshData() {
    this.nDays && this.nDays !== 0 && this.subNo && this.subNo !== 0 ?
      this.backendSrv.getMinAppointmentNumber(this.subNo, this.nDays).subscribe((data: IAppUsers[]) => this.rowData = data)
      : this.backendSrv.getAllUsers().subscribe((data: IAppUsers[]) => this.rowData = data)
  }

  countDisplayedRows(params: any) {
    this.visibleRows = params.api.getDisplayedRowCount();
    this.filterModel = params.api.getFilterModel()
    if (this.filterModel) {
      let activeFilters: any[] = []
      Object.keys(this.filterModel).forEach((filterName: string) => {
        this.columnDefs.forEach(col => {
          if (col.field === filterName) activeFilters.push({ name: col.headerName, field: col.field })
        })
      });
      this.activeFiltersModel = activeFilters.sort((a: any, b: any) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    }
    this.refreshFilterData()
  }

  onGridReady(params: any) {
    console.clear()
    const defaultColDefs = localStorage.getItem('defaultColDef')
    this.backendSrv.usersData$.subscribe(data => data.length > 0 ? this.rowData = data : this.refreshData())
    this.gridApi = params.api
    if (defaultColDefs) {
      let colDefsObj = JSON.parse(defaultColDefs)
      colDefsObj[0].cellRendererParams = { onClick: this.onBtnClick.bind(this) }
      colDefsObj.forEach((col: any) => {
        const dateFields: string[] = ['purchase_date', 'created_at', 'updated_at']
        if (dateFields.includes(col.field)) col.filterParams = dateFilterParams
      });
      this.gridApi.setColumnDefs(colDefsObj)
    }
    this.planFilter = params.api.getFilterInstance("p_name").getFrameworkComponentInstance();
    this.professionFilter = params.api.getFilterInstance("profession_id").getFrameworkComponentInstance();
    this.countryFilter = params.api.getFilterInstance("u_s_country").getFrameworkComponentInstance();
    this.stateFilter = params.api.getFilterInstance("state_name").getFrameworkComponentInstance();
    this.roleFilter = params.api.getFilterInstance("user_role").getFrameworkComponentInstance();
    this.gdprFilter = params.api.getFilterInstance("gdpr").getFrameworkComponentInstance();
    this.columnGridApi = params.columnApi
  }

  refreshFilterData() {
    this.countryFilter?.onNewRowsLoaded(), this.professionFilter?.onNewRowsLoaded(), this.planFilter?.onNewRowsLoaded()
    this.roleFilter?.onNewRowsLoaded(), this.stateFilter?.onNewRowsLoaded(), this.gdprFilter?.onNewRowsLoaded()
  }

  resetAllFilters(onlyGrid: boolean = false) {
    if (Object.keys(this.filterModel).length) {
      this.gridApi.setFilterModel(null)
      this.planFilter.unselectEverything(), this.professionFilter.unselectEverything(), this.countryFilter.unselectEverything()
      this.roleFilter.unselectEverything(), this.stateFilter.unselectEverything(), this.gdprFilter.unselectEverything()
    }
    if (!onlyGrid) {
      this.searchValue = ''
      this.subNo = undefined
      this.nDays = undefined
      this.msgSrv.openSnackBarMessage({ title: '', description: `All search criteria has been removed.` }, 'warning', 1.5)
    }
  }

  removeChipFilter(filter: string) {
    const filterInstance = this.gridApi.getFilterInstance(filter)
    if (this.customFilters.indexOf(filter) === -1) {
      filterInstance.setModel(null)
      this.gridApi.onFilterChanged()
    }
    else filterInstance.getFrameworkComponentInstance().unselectEverything()
  }

  restoreFilters(usersFilter: any) {
    this.resetAllFilters(true)
    const usersFilterModel = usersFilter.filterModel
    const customFilters = Object.keys(usersFilterModel).filter(x => this.customFilters.includes(x))
    const defaultFilters = Object.keys(usersFilterModel).filter(x => !this.customFilters.includes(x))
    defaultFilters.forEach((df: string) => {
      let filterInstance = this.gridApi.getFilterInstance(df)
      filterInstance.setModel(usersFilterModel[df])
    })
    customFilters.forEach((cf: string) => {
      let filterInstance = this.gridApi.getFilterInstance(cf).getFrameworkComponentInstance();
      filterInstance.addValues(Object.keys(usersFilterModel[cf]))
    })
    this.gridApi.onFilterChanged()
    this.msgSrv.openSnackBarMessage({ title: 'Filters Config', description: `Configuration "${usersFilter.filterName}" restored.` }, 'info', 2)
  }

  saveFilters() {
    const dialogRef = this.dialog.open(FilterNamePromptComponent, {
      width: '285px',
      position: { top: '250px' }
    });
    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        const userFilterModel = this.gridApi.getFilterModel();
        this.filterStoreSrv.updateFilters(name, userFilterModel)
        this.msgSrv.openSnackBarMessage({ title: 'Filters Config', description: `Filter "${name}" stored successfully.` }, 'info', 2.5)
      }
    })
  }

  removeFilterConfig(filter: string) {
    this.filterStoreSrv.deleteFilter(filter)
    this.msgSrv.openSnackBarMessage({ title: 'Filters Config', description: `Removed "${filter}" from filters configuration.` }, 'warning', 2.5)
  }

  filterTooltip(fname: string) {
    let tooltip = ''
    const customFilters = Object.keys(this.filterModel).filter(x => this.customFilters.includes(x))
    Object.keys(this.filterModel[fname]).forEach(key =>
      tooltip += customFilters.includes(fname) ? `${key}\n` : `${key} - ${this.filterModel[fname][key]}\n`
    )
    return tooltip
  }

  saveDefaultColDef() {
    const columnDefs = this.gridApi.getColumnDefs();
    localStorage.setItem('defaultColDef', JSON.stringify(columnDefs))
    this.msgSrv.openSnackBarMessage({ title: '', description: 'Saved default column configuration.' })
  }
  clearLocalStorage() {
    localStorage.removeItem("defaultColDef")
    location.reload()
  }
  setAutoRefresh(event: any) {
    this.autoRefresh = event.checked
    localStorage.setItem('autoRefresh', this.autoRefresh + '')
  }

  toggleColumnVisibility(column: any, visibility: any) { this.columnGridApi.setColumnVisible(column, visibility) }

  onBtnExport() { this.gridApi.exportDataAsCsv() }

  private onBtnClick(e: any) { return }
}
