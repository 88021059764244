import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AgGridModule } from 'ag-grid-angular';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { NavbarComponent } from './_shared/components/navbar/navbar.component';
import { AngularMaterialModule } from './_shared/modules/angular-material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersComponent } from './users/users.component';
import { BackendService } from './_shared/services/backend.service';
import { AuthService } from './_shared/services/auth.service';
import { AuthGuard } from './_shared/guards/auth.guard';
import { SelectFilterComponent } from './users/select-filter/select-filter.component';
import { RowCellButtonsComponent } from './users/row-cell-buttons/row-cell-buttons.component';
import { LoaderService } from './_shared/interceptors/loader-interceptor.service';
import { ViewUserComponent } from './view-user/view-user.component';
import { UserAppointmentsComponent } from './user-appointments/user-appointments.component';
import { UserClientsComponent } from './user-clients/user-clients.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { MassMessagesComponent } from './mass-messages/mass-messages.component';
import { ErrorService } from './_shared/interceptors/error-interceptor.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { SetActiveCellButtonComponent } from './view-user/grid-cells/set-active-cell-button/set-active-cell-button.component';
import { OrderCellButtonComponent } from './view-user/grid-cells/order-cell-button/order-cell-button.component';
import { DeviceLogCellButtonComponent } from './view-user/grid-cells/device-log-cell-button/device-log-cell-button.component';
import { PushInfoPopupComponent } from './push-notifications/push-info-popup/push-info-popup.component';
import { FiltersStoreService } from './users/filters-store.service';
import { FilterNamePromptComponent } from './users/filter-name-prompt/filter-name-prompt.component';
import { AuthTokenMiddlewareService } from './_shared/interceptors/auth-token-interceptor.service';
import { CanReadGuard } from './_shared/guards/can-read.guard';
import { CanEditGuard } from './_shared/guards/can-edit.guard';
import { ViewInstanceCellButtonComponent } from './user-appointments/view-instance-cell-button/view-instance-cell-button.component';
import { InstancesModalComponent } from './user-appointments/instances-modal/instances-modal.component';
import { InternetInterceptorService } from './_shared/interceptors/internet-interceptor.service';
import { ViewRecipientsCellButtonComponent } from './mass-messages/view-recipients-cell-button/view-recipients-cell-button.component';
import { RecipientsModalComponent } from './mass-messages/recipients-modal/recipients-modal.component';
import { CanAdminGuard } from './_shared/guards/can-admin.guard';
import { BusinessDataPanelComponent } from './view-user/business-data-panel/business-data-panel.component';
import { UserDetailsPanelComponent } from './view-user/user-details-panel/user-details-panel.component';
import { UserSettingsPanelComponent } from './view-user/user-settings-panel/user-settings-panel.component';
import { RevenueCatComponent } from './view-user/revenue-cat/revenue-cat.component';
import { TransactionsCellComponent } from './user-appointments/transactions-cell/transactions-cell.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    UsersComponent,
    SelectFilterComponent,
    RowCellButtonsComponent,
    ViewUserComponent,
    UserAppointmentsComponent,
    UserClientsComponent,
    EditUserComponent,
    MassMessagesComponent,
    UserProfileComponent,
    PushNotificationsComponent,
    SetActiveCellButtonComponent,
    OrderCellButtonComponent,
    DeviceLogCellButtonComponent,
    PushInfoPopupComponent,
    FilterNamePromptComponent,
    ViewInstanceCellButtonComponent,
    InstancesModalComponent,
    ViewRecipientsCellButtonComponent,
    RecipientsModalComponent,
    BusinessDataPanelComponent,
    UserDetailsPanelComponent,
    UserSettingsPanelComponent,
    RevenueCatComponent,
    TransactionsCellComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'admin-area-web'),
    AgGridModule.withComponents(
      [
        SelectFilterComponent,
        RowCellButtonsComponent,
        DeviceLogCellButtonComponent,
        OrderCellButtonComponent,
        SetActiveCellButtonComponent
      ]
    ),
    AngularFireAuthModule,
    HttpClientModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    BackendService,
    AuthService,
    AuthGuard,
    CanReadGuard,
    CanEditGuard,
    CanAdminGuard,
    FiltersStoreService,
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenMiddlewareService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
