import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AuthService } from 'src/app/_shared/services/auth.service';

@Component({
  selector: 'app-row-cell-buttons',
  templateUrl: './row-cell-buttons.component.html',
  styleUrls: ['./row-cell-buttons.component.sass']
})
export class RowCellButtonsComponent implements ICellRendererAngularComp {
  user: any;
  private params: any;
  viewHref: string = '';
  editHref: string = '';

  constructor(
    private router: Router,
    public auth: AuthService
  ) {
    this.auth.user$.subscribe(user => this.user = user)
  }

  agInit(params: any): void {
    this.params = params
    this.viewHref = 'admin/view/user/' + this.params.node.data.id
    this.editHref = 'admin/edit/user/' + this.params.node.data.id
  }

  refresh(params?: any): boolean { return true }

  goTo($event: any, page: string) {
    if (this.params.onClick instanceof Function) {
      $event.preventDefault()
      this.router.navigate(['admin', page, 'user', this.params.node.data.id])
    }
  }
}
